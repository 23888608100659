import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { lastValueFrom, map } from 'rxjs';
import {
  FileControllerService,
  SubscriptionControllerService
} from 'src/app/admin-api';
import { CustomizationFormComponent } from 'src/app/components/customization/customization-form.component';
import { AppDialogService } from 'src/app/services/dialog.service';
import { ImageService } from 'src/app/services/image.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-edition',
  templateUrl: './edition.component.html',
  styleUrls: ['./edition.component.scss'],
  providers: [MessageService]
})
export class EditionComponent
  extends CustomizationFormComponent
  implements OnInit
{
  @Input()
  editionId: string;
  editionDate: string;
  edition: any;
  iconCombo: string;
  iconBox: string;
  iconBag: string;
  iconPass: string;
  iconsArray: any[] = [
    {
      editionId: null,
      icon: null
    },
    {
      editionId: null,
      icon: null
    },
    {
      editionId: null,
      icon: null
    },
    {
      editionId: null,
      icon: null
    }
  ];

  constructor(
    private fb: FormBuilder,
    private subscriptionControllerService: SubscriptionControllerService,
    override router: Router,
    override config: PrimeNGConfig,
    override imageService: ImageService,
    override fileService: FileControllerService,
    private messageService: MessageService
  ) {
    super(router, config, imageService, fileService);
  }

  async ngOnInit() {
    LoaderService.showLoader();
    await this.getDocument();
    this.initializeForm();
    LoaderService.showLoader(false);
  }

  override async getDocument(): Promise<void> {
    if (this.editionId !== 'new') {
      try {
        const editonId = Number(this.editionId);
        this.edition = await lastValueFrom(
          this.subscriptionControllerService
            .getEditonCustomizationById(editonId)
            .pipe(map((data) => data.result))
        );
        this.iconsArray = this.edition.icons;
      } catch (error) {
        AppDialogService.showErrorDialog(error);
        return;
      }
    }
  }

  override initializeForm(): void {
    this.form = this.fb.group({
      editionTheme: [this.edition?.editionTheme, Validators.required],
      editionDateTime: [
        this.edition?.editionDateTime
          ? new Date(this.edition?.editionDateTime)
          : null,
        Validators.required
      ],
      editionDate: [this.edition?.editionDate, Validators.required],
      editionCardBackgroundColor: [
        this.edition?.editionCardBackgroundColor || '#FFFFFF'
      ],
      editionCardDesktop: [this.edition?.editionCardDesktop],
      editionCardMobile: [this.edition?.editionCardMobile],
      lastEditionsCarouselCard: [this.edition?.lastEditionsCarouselCard],
      lastEditionsCarouselCardAlt: [this.edition?.lastEditionsCarouselCardAlt],
      icons: this.iconsArray
    });

    if (this.editionId !== 'new') this.form.get('editionDateTime')?.disable();

    this.form
      .get('editionDateTime')
      ?.valueChanges.subscribe((newValue: Date) => {
        if (newValue instanceof Date) {
          this.convertDateToEditionDate(newValue);

          this.form.get('editionDate')?.setValue(this.editionDate);
          this.setIconsArrayEdtionIdValue(`7${this.editionDate}`, 0);
          this.setIconsArrayEdtionIdValue(`1${this.editionDate}`, 1);
          this.setIconsArrayEdtionIdValue(`5${this.editionDate}`, 2);
          this.setIconsArrayEdtionIdValue(`6${this.editionDate}`, 3);
        }
      });
  }

  async handleFileSelect(
    event: { files: File[] },
    inputName: string,
    expectedWidth: number,
    expectedHeight: number,
    maxWeight: number,
    pInput: FileUpload,
    index?: number
  ): Promise<void> {
    LoaderService.showLoader();
    this.fileNamePattern = `glam-edition-${new Date().getTime()}`;

    const compressedFileUrl = await this.upload(
      event,
      inputName,
      expectedWidth,
      expectedHeight,
      maxWeight,
      pInput
    );

    if (index < 4) this.setIconsArrayIconValue(compressedFileUrl, index);

    LoaderService.showLoader(false);
    pInput.clear();
  }

  override async save() {
    this.form.get('icons').setValue(this.iconsArray);
    LoaderService.showLoader();
    try {
      if (this.editionId === 'new') {
        const edition = await lastValueFrom(
          this.subscriptionControllerService
            .createEditionCustomization(this.form.value)
            .pipe(map((data) => data.result))
        );
        this.messageService.add({
          severity: 'success',
          detail: 'Edição cadastrada com sucesso.',
          summary: 'Sucesso'
        });
        this.router.navigate([
          '/configuracoes/editions/' + edition.editionDate
        ]);
      } else {
        await lastValueFrom(
          this.subscriptionControllerService.updateEditionCustomization(
            this.form.value
          )
        );

        this.messageService.add({
          severity: 'success',
          detail: 'Edição atualizada com sucesso.',
          summary: 'Sucesso'
        });
      }
      this.filesUploaded = [];
      await this.afterSubmit();
    } catch (e) {
      AppDialogService.showErrorDialog(e);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  subscriptionId(index: number): string {
    return index === 0 ? '7' : index === 1 ? '1' : index === 2 ? '5' : '6';
  }

  setIconsArrayIconValue(newValue: string, index: number): void {
    this.iconsArray[index].icon = newValue;
  }

  setIconsArrayEdtionIdValue(newValue: string, index: number): void {
    this.iconsArray[index].editionId = Number(newValue);
  }

  convertDateToEditionDate(value: Date) {
    this.editionDate =
      JSON.stringify(value.getFullYear()) +
      (value.getMonth() + 1).toString().padStart(2, '0');
  }

  get icons() {
    return this.form.get('icons') as FormArray;
  }

  override get filePath(): string {
    return '/editions/';
  }

  override get model() {
    return this.edition;
  }

  override get modelId() {
    return this.editionId;
  }
}
