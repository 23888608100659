<h2>Atualização Tabela de Frete (Loja)</h2>
<h3>Ranges de peso:</h3>
<div class="item-list">
  <ng-container *ngIf="weightRangeServiceCompanyRange()">
    <div class="item" *ngFor="let range of weightRangeServiceCompanyRange()">
      <h3 class="item-title">
        De
        {{ range.initialWeightRange / 1000 | number : '1.3-3' }} a
        {{ range.endWeightRange / 1000 | number : '1.3-3' }} KG
      </h3>
      <div
        class="item-status"
        [ngClass]="getGridClass(range.status, range.inProduction)"
        [title]="getDescription(range.status, range.inProduction)"
      ></div>
    </div>
  </ng-container>
</div>

<div *ngIf="synchronism()">
  <p class="loading-text">
    Sincronismo em progresso<span class="loading-dots">...</span>
  </p>
</div>

<br />
<div class="button-container">
  <p-button
    label="Continuar operação"
    severity="primary"
    class="continue-button"
    pTooltip="Continua o processo de atualização da tabela de frete em SIMULATION"
    tooltipPosition="bottom"
    (onClick)="continueUpdate()"
    [disabled]="continueButtonDisabled"
  ></p-button>
  <p-button
    label="Pausar atualização"
    severity="danger"
    class="stop-button"
    pTooltip="Pausa o processo de atualização da tabela de frete em SIMULATION"
    tooltipPosition="bottom"
    (onClick)="stopUpdate()"
    [disabled]="stopButtonDisabled"
  ></p-button>
  <p-button
    label="Enviar nova tabela para produção"
    severity="success"
    class="update-table-button"
    pTooltip="Enviar a tabela de SIMULATION para PRODUÇÃO"
    tooltipPosition="bottom"
    (onClick)="updateServiceCompanyRange()"
    [disabled]="updateButtonDisabled"
  ></p-button>
  <p-button
    label="Cancelar atualização"
    severity="danger"
    class="cancel-button"
    pTooltip="Interrompe por completo o processo de atualização da tabela de frete em SIMULATION"
    tooltipPosition="bottom"
    (onClick)="cancelUpdate()"
    [disabled]="cancelButtonDisabled"
  ></p-button>
  <p-button
    label="Realizar rollback"
    severity="secondary"
    class="rollback-button"
    pTooltip="Pega a última tabela de frete do backup e coloca em PRODUÇÃO"
    tooltipPosition="bottom"
    (onClick)="rollbackServiceCompanyRange()"
    [disabled]="rollbackButtonDisabled"
  ></p-button>
  <p-button
    label="Buscar nova tabela de frete"
    severity="primary"
    class="sync-button"
    pTooltip="Realiza o processo de atualização da tabela de frete em SIMULATION"
    tooltipPosition="bottom"
    (onClick)="syncUpdateServiceCompanyRange()"
    [disabled]="syncButtonDisabled"
  ></p-button>
</div>
<br />

<h2>Últimas atualizações realizadas</h2>
<div>
  <ng-container *ngIf="logger && cols">
    <app-table
      [elements]="logger"
      [addButton]="false"
      [cols]="cols"
      emptyMessage="Nenhuma atualização da tabela de frete encontrada."
      [exportExcel]="false"
      [exportPdf]="false"
      [lazy]="false"
      sortField="dateCreated"
      [sortOrder]="1"
      [changeColumns]="true"
      [showTitle]="false"
      modelName="registros"
    ></app-table>
  </ng-container>
</div>
