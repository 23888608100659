<form class="form-b4a" [formGroup]="form" *ngIf="form" (submit)="submit()">
  <p-listbox
    [options]="productVariants"
    formControlName="productVariantId"
    optionLabel="productVariantName"
    optionValue="productVariantId"
    [filter]="true"
    styleClass="fixed-height"
    filterPlaceHolder="Pesquise pelo Nome, Marca, EAN ou SKU"
    (onFilter)="filter($event)"
    [style]="{ height: '50vh' }"
  >
    <ng-template pTemplate="empty">
      <ng-container *ngIf="loading">
        <i class="pi pi-spinner pi-spin"></i>
      </ng-container>
      <ng-container *ngIf="!loading">
        {{
          searchValid
            ? 'Nenhuma variante encontrada'
            : 'Digite ao menos 3 caracteres'
        }}
      </ng-container>
    </ng-template>
    <ng-template pTemplate="emptyFilter" let-variant>
      <ng-container *ngIf="loading">
        <i class="pi pi-spinner pi-spin"></i>
      </ng-container>
      <ng-container *ngIf="!loading && !productVariants.length">
        {{
          searchValid
            ? 'Nenhuma variante encontrada'
            : 'Digite ao menos 3 caracteres'
        }}
      </ng-container>
      <ng-container *ngIf="!loading && productVariants.length">
        {{ variant.productVariantName }}
      </ng-container>
    </ng-template>
  </p-listbox>
  <div class="buttons">
    <p-button
      type="submit"
      label="Salvar"
      severity="primary"
      [disabled]="!form.valid"
    />
  </div>
</form>
