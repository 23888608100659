import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AcquisitionDashboardComponent } from './acquisition-dashboard/acquisition-dashboard.component';
import { AnalyticsDashboardComponent } from './analytics-dashboard/analytics-dashboard.component';
import { CouponsDashboardComponent } from './coupons-dashboard/coupons-dashboard.component';
import { DeliveryDashboardComponent } from './delivery-dashboard/delivery-dashboard.component';
import { IncomeDashboardComponent } from './income-dashboard/income-dashboard.component';
import { EditionRatingAndScoresDashboardComponent } from './rating-and-scores-dashboard/edition-rating-and-scores-dashboard/edition-rating-and-scores-dashboard.component';
import { RatingAndScoresDashboardComponent } from './rating-and-scores-dashboard/rating-and-scores-dashboard.component';
import { SubscriptionsDashboardComponent } from './subscriptions-dashboard/subscriptions-dashboard.component';
import { UpgradeDowngradeDashboardComponent } from './upgrade-downgrade-dashboard/upgrade-downgrade-dashboard.component';
import { WishlistDashboardComponent } from './wishlist-dashboard/wishlist-dashboard.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'subscriptions'
  },
  {
    path: 'acquisition',
    component: AcquisitionDashboardComponent
  },
  {
    path: 'acquisition/:editionId',
    component: AcquisitionDashboardComponent
  },
  {
    path: 'income',
    component: IncomeDashboardComponent
  },
  {
    path: 'income/:editionId',
    component: IncomeDashboardComponent
  },
  {
    path: 'subscriptions',
    component: SubscriptionsDashboardComponent
  },
  {
    path: 'subscriptions/:editionId',
    component: SubscriptionsDashboardComponent
  },
  {
    path: 'coupons',
    component: CouponsDashboardComponent
  },
  {
    path: 'coupons/:editionId',
    component: CouponsDashboardComponent
  },
  {
    path: 'rating-and-scores',
    component: RatingAndScoresDashboardComponent
  },
  {
    path: 'rating-and-scores/:editionId',
    component: EditionRatingAndScoresDashboardComponent
  },
  {
    path: 'upgrade-downgrade',
    component: UpgradeDowngradeDashboardComponent
  },
  {
    path: 'upgrade-downgrade/:editionId',
    component: UpgradeDowngradeDashboardComponent
  },
  {
    path: 'delivery',
    component: DeliveryDashboardComponent
  },
  {
    path: 'delivery/:editionId',
    component: DeliveryDashboardComponent
  },
  {
    path: 'analytics',
    component: AnalyticsDashboardComponent
  },
  {
    path: 'wishlists',
    component: WishlistDashboardComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardsRoutingModule {}
