<div class="page-title">Detalhes da composição</div>
<div class="details composition-details" *ngIf="composition">
  <div class="details__left">
    <div class="card card-user">
      <div class="image"></div>
      <div class="card-body">
        <div class="user">
          <img class="avatar" [src]="editionImage" *ngIf="editionImage" />
          <img
            class="avatar"
            src="assets/images/no_img_available.jpg"
            *ngIf="!editionImage"
          />
          <h4 *ngIf="!edit">
            {{ composition.compositionName }}
            <ng-container *ngIf="!composition.dateSyncERP">
              <br />
              <p-button
                styleClass="p-button-xs"
                *ngxPermissionsOnly="allowedPermissions"
                severity="danger"
                size="small"
                label="Editar nome"
                icon="pi pi-pencil"
                iconPos="left"
                (onClick)="editCompositionName()"
              ></p-button>
            </ng-container>
          </h4>
          <p *ngIf="edit">
            <input
              id="compositionName"
              type="text"
              pInputText
              [(ngModel)]="composition.compositionName"
              (blur)="changeCompositionName()"
            />
          </p>
          <p>Edição: {{ editionName }}</p>
          <p>Preço custo: {{ composition.costTotal | currency : 'BRL' }}</p>
          <p>Preço mercado: {{ composition.saleTotal | currency : 'BRL' }}</p>
          <p>Preço nota: {{ composition.invoiceTotal | currency : 'BRL' }}</p>
          <p>
            Data criação:
            {{ composition.dateCreated | date : 'dd/MM/yyyy HH:mm:ss' }}
          </p>
          <p *ngIf="composition.dateSyncERP">
            Data exportação:
            {{ composition.dateSyncERP | date : 'dd/MM/yyyy HH:mm:ss' }}
          </p>
          <div class="actions" *ngxPermissionsOnly="allowedPermissions">
            <p-button
              label="Excluir"
              icon="pi pi-trash"
              *ngIf="!composition.dateSyncERP"
              (onClick)="deleteComposition()"
              severity="danger"
              size="small"
            ></p-button>
            <p-button
              label="Validar assinantes"
              icon="pi pi-verified"
              *ngIf="!composition.dateSyncERP"
              severity="dark"
              size="small"
              (onClick)="validateSubscribers()"
            ></p-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="details__right">
    <div class="card">
      <p-tabView
        [activeIndex]="selectedTab"
        (activeIndexChange)="tabChanged($event)"
        *ngIf="ready"
      >
        <p-tabPanel header="Variantes ({{ compositionProducts?.length || 0 }})">
          <app-table
            *ngIf="variantCols() && compositionProducts"
            [cols]="variantCols()"
            [elements]="compositionProducts"
            modelName="variantes"
            sortField="productVariantName"
            [sortOrder]="-1"
            [lazy]="false"
            [exportPdf]="false"
            [exportExcel]="false"
            [addButton]="false"
            [showTitle]="false"
            [changeColumns]="true"
            [rowBackground]="rowBackground"
            (rowItemChanged)="changeCompositionProduct($event)"
            (booleanAction)="changeCompositionProduct($event)"
            [falseValueFunction]="falseValue"
            [trueValueFunction]="trueValue"
            [dropdownFilters]="dropdownFilters()"
            [multiSelectFilter]="true"
            (changedFilter)="filterChanged($event)"
          ></app-table>
        </p-tabPanel>
        <p-tabPanel
          header="Assinantes ({{
            compositionSubscribers?.length | number : '1.0-0'
          }})"
        >
          <div *ngIf="subscriberEditionTotals?.length">
            <h4>Resumo das edições</h4>
            <p-table [value]="subscriberEditionMilleniumTotals">
              <ng-template pTemplate="header">
                <tr>
                  <th>Status edição</th>
                  <th
                    *ngFor="let total of subscriberEditionTotals; let i = index"
                    [ngStyle]="{
                      'background-color': subscriberStatusColor(total.id),
                      color: 'white',
                      'text-align': 'center'
                    }"
                    [attr.colspan]="total.statusList?.length"
                  >
                    {{ total.status | titlecase }}
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body">
                <tr>
                  <td class="fixed-data">Status Millennium</td>
                  <ng-container
                    *ngFor="
                      let total of subscriberEditionMilleniumTotals;
                      let i = index
                    "
                  >
                    <td
                      class="millennium-status"
                      *ngFor="let mill of total.statusList"
                    >
                      {{ mill.status | titlecase }} ({{
                        mill.subscribers | number : '1.0-0'
                      }})
                    </td>
                  </ng-container>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <hr />
          <app-table
            *ngIf="subscriberCols && compositionSubscribers"
            [cols]="subscriberCols"
            [elements]="compositionSubscribers"
            modelName="assinantes"
            sortField="personName"
            [sortOrder]="-1"
            [lazy]="false"
            [exportPdf]="false"
            [exportExcel]="false"
            [addButton]="false"
            [showTitle]="false"
            [changeColumns]="true"
            [showFooter]="true"
            [dropdownFilters]="dropdownFilters()"
            [multiSelectFilter]="true"
          >
          </app-table>
        </p-tabPanel>
        <p-tabPanel
          header="Produção ({{
            compositionProductionSummary?.quantity || 0 | number : '1.0-0'
          }})"
        >
          <app-composition-production
            *ngIf="compositionProductionSummary && user?.username"
            [composition]="compositionProductionSummary"
            [username]="user.username"
            (productionChanged)="compositionProductionSummary = $event"
          ></app-composition-production>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>
<p-confirmDialog styleClass="danger"></p-confirmDialog>
<p-toast></p-toast>
