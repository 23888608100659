/* eslint-disable @typescript-eslint/no-explicit-any */
import { DecimalPipe } from '@angular/common';
import {
  Component,
  inject,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Listbox, ListboxFilterEvent } from 'primeng/listbox';
import { lastValueFrom, map } from 'rxjs';
import {
  Coupon,
  CouponControllerService,
  CouponProductVariantCreateRequest,
  ProductControllerService,
  ProductVariantSummary
} from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-coupon-product-variant-form',
  templateUrl: './coupon-product-variant-form.component.html',
  styleUrls: ['./coupon-product-variant-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DecimalPipe]
})
export class CouponProductVariantFormComponent implements OnInit {
  @ViewChild(Listbox) listBox: Listbox | undefined;

  private couponService = inject(CouponControllerService);
  private config = inject(DynamicDialogConfig);
  private ref = inject(DynamicDialogRef);
  private productService = inject(ProductControllerService);
  private decimalPipe = inject(DecimalPipe);

  form = new FormGroup({
    productVariantId: new FormControl<number>(null, [
      Validators.required,
      Validators.min(1)
    ])
  });
  coupon: Coupon | undefined;
  groupVariant: number | undefined;
  lastGroupVariant = -1;
  productVariants: Array<ProductVariantSummary> = [];
  searchValid = false;
  search = '';
  loading = false;
  searching = false;

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    this.coupon = this.config.data?.coupon;
    this.groupVariant = this.config.data?.groupVariant;
    this.lastGroupVariant = this.config.data?.lastGroupVariant;
    LoaderService.showLoader(false);
  }

  async submit(): Promise<void> {
    if (this.form?.valid && this.coupon) {
      LoaderService.showLoader();
      try {
        const body: CouponProductVariantCreateRequest = {
          couponId: this.coupon?.couponId,
          couponProductVariantType: this.groupVariant !== undefined ? 0 : 1,
          groupVariant:
            this.groupVariant !== undefined
              ? this.groupVariant
              : this.lastGroupVariant + 1,
          quantity: 1,
          productVariantId: this.form.value.productVariantId
        };
        const product = await lastValueFrom(
          this.couponService.addProductVariantToCoupon(body)
        );
        this.ref.close(product);
      } catch (error: any) {
        AppDialogService.showErrorDialog(error);
      }
      LoaderService.showLoader(true);
    } else if (this.form.valid) {
      this.ref.close(this.product);
    }
  }

  async findProductVariants(search?: string): Promise<void> {
    if (!search?.trim().length && !this.form?.value.productVariantId) return;
    try {
      this.productVariants =
        (await lastValueFrom(
          this.productService
            .searchVariantsByNameOrExternalIdOrBrandOrInternalEAN(
              search || this.form?.value.productVariantId?.toString()
            )
            .pipe(
              map((data) => {
                data.result = data.result.map((r) => ({
                  ...r,
                  productVariantName: `${r.productVariantName} ${
                    r.internalEAN ? '[EAN:' + r.internalEAN + ']' : ''
                  } ${r.externalId ? '(SKU:' + r.externalId + ')' : ''} - ${
                    r.brandName
                  }  (Estoque: ${this.decimalPipe.transform(
                    r.inStockReserveMarketing || 0,
                    '1.0-0'
                  )})`
                }));
                data.result.sort((p1, p2) => {
                  return (
                    FormUtil.semAcento(p1.productVariantName)
                      .replace("'", '')
                      .toLowerCase()
                      .localeCompare(
                        FormUtil.semAcento(p2.productVariantName)
                          .replace("'", '')
                          .toLowerCase()
                      ) || p1.productVariantId - p2.productVariantId
                  );
                });
                return data.result
                  .filter((p) => p.inStockReserveMarketing > 0)
                  .concat(
                    data.result.filter(
                      (p) =>
                        !p.inStockReserveMarketing ||
                        p.inStockReserveMarketing < 0
                    )
                  );
              })
            )
        )) || [];
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  filter($event: ListboxFilterEvent): void {
    this.searchValid = $event.filter?.length > 2;
    this.search = $event.filter;
    if (this.searchValid) {
      this.loading = true;
      setTimeout(async () => {
        if (this.search === $event.filter && !this.searching) {
          this.searching = true;
          await this.findProductVariants(this.search);
          this.loading = false;
          this.searching = false;
        }
      }, 500);
    } else {
      this.productVariants = [];
    }
  }

  get product(): ProductVariantSummary {
    return this.productVariants?.find(
      (p) => p.productVariantId === this.form.value.productVariantId
    );
  }
}
