import { Inject, Injectable } from '@angular/core';
import {
  CategoryRepository,
  Product,
  ProductRepository,
  ShopPrice,
  Variant,
  VariantRepository,
  Where
} from '@infrab4a/connect';
import { Papa } from 'ngx-papaparse';
import { MessageService } from 'primeng/api';
import { ProductBatchUpdateJSON } from 'src/app/connect-api/models/batch/ProductBatchUpdateJSON';
import { BaseConnectService } from '../base-connect.service';
import { BatchService } from './batch.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsBatchService implements BatchService {
  itemsForUpload: Partial<Product>[];
  chunkSize = 100;
  completeCount = 0;
  errors = [];

  constructor(
    private papa: Papa,
    @Inject('ProductRepository') private repository: ProductRepository,
    @Inject('VariantRepository') private variantRepository: VariantRepository,
    @Inject('CategoryRepository')
    private categoryRepository: CategoryRepository,
    private messageService: MessageService
  ) {}

  async parse(input: File): Promise<Partial<Product>[]> {
    return new Promise<Partial<Product>[]>((resolve, reject) => {
      if (!input) return resolve((this.itemsForUpload = []));

      this.papa.parse(input, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: async (results) => {
          try {
            if (results.errors.length > 0) throw results.errors.shift();

            this.itemsForUpload = await Promise.all(
              results.data
                .filter((c) => Object.keys(c).some((k) => c[k]))
                .map(
                  async (product: ProductBatchUpdateJSON, index) =>
                    await this.convertToProduct(product, index)
                )
            );

            resolve(this.itemsForUpload);
          } catch (error) {
            reject(new Error(`Arquivo inválido: ${error.message}`));
          }
        }
      });
    });
  }

  async update(): Promise<Array<Partial<Product>>> {
    if (this.itemsForUpload?.length <= 0) {
      return [];
    }

    this.errors = [];

    const chunks = this.getChunkedProductList();
    return await this.startImportation(chunks);
  }

  get importProgress(): number {
    return +((this.completeCount * 100) / this.itemsForUpload.length).toFixed(
      0
    );
  }

  private async convertToProduct(
    product: ProductBatchUpdateJSON,
    index: number
  ): Promise<Partial<Product>> {
    const hasAnyMetadata = product.metadataDescription || product.metadataTitle;

    if (!product.EAN)
      throw new Error(`Produtos sem campo de EAN na linha ${index + 2}!`);

    const categoryId: string = await this.searchCategoryReference(
      product.category_reference,
      index
    );

    const tags = product.tags
      ? product.tags
          .toLowerCase()
          .split(';')
          .map((s) => s.trim())
          .filter((s) => s != '')
      : null;

    const filters = product.filters
      ? product.filters
          .toLowerCase()
          .split(';')
          .map((s) => s.trim())
          .filter((s) => s != '')
      : null;

    const validLabels = ['outlet', 'promocao', 'ultimasunidades', 'glamstar'];
    if (
      product.label &&
      !validLabels.includes(product.label.toLowerCase().trim())
    )
      throw new Error(
        `Label de produto inválido na linha: ${
          index + 2
        }. Os valores permitidos são: outlet, promocao, ultimasunidades, glamstar`
      );
    else if (product.label == null) product.label = 'null';

    return Object.entries({
      EAN: product.EAN.toString(),
      price: this.buildProductPrices(product, index),
      ...([true, false].includes(product.published) && {
        published: product.published
      }),
      ...(product.description ||
      product.differentials ||
      product.whoMustUse ||
      product.howToUse ||
      product.brandDescription ||
      product.ingredients ||
      product.purpose
        ? {
            description: {
              ...(product.description
                ? { description: product.description }
                : {}),
              ...(product.differentials
                ? { differentials: product.differentials }
                : {}),
              ...(product.whoMustUse ? { whoMustUse: product.whoMustUse } : {}),
              ...(product.howToUse ? { howToUse: product.howToUse } : {}),
              ...(product.brandDescription
                ? { brand: product.brandDescription }
                : {}),
              ...(product.ingredients
                ? { ingredients: product.ingredients }
                : {}),
              ...(product.purpose ? { purpose: product.purpose } : {})
            }
          }
        : {}),
      ...(hasAnyMetadata && {
        metadata: {
          ...(product.metadataDescription
            ? { description: product.metadataDescription }
            : {}),
          ...(product.metadataTitle ? { title: product.metadataTitle } : {})
        }
      }),
      ...(product.type && { type: product.type }),
      ...(product.tags && {
        tags: [...new Set(tags)]
      }),
      ...(product.filters && {
        filters: [...new Set(filters)]
      }),
      ...(product.brand && { brand: product.brand }),
      ...(product.weight && { weight: product.weight }),
      ...(product.name && { name: product.name }),
      ...(product.slug && { slug: product.slug }),
      ...(product.NCM && { NCM: product.NCM.toString() }),
      ...(product.sku && { sku: product.sku.toString().trim() }),
      ...(product.CEST && { CEST: product.CEST.toString() }),
      ...(product.stock && { stock: { quantity: product.stock } }),
      ...(product.video && { video: product.video }),
      ...(product.category_reference && { categoryId }),
      ...(product.label && { label: this.getLabel(product.label) })
    }).reduce(
      (iProduct, [key, value]) =>
        ![undefined, null].includes(value)
          ? { ...iProduct, [key]: value }
          : iProduct,
      {}
    );
  }

  private getLabel(input: string) {
    if (input.toLowerCase().trim() == 'promocao') return 'on-sale';
    if (input.toLowerCase().trim() == 'ultimasunidades') return 'last-units';
    if (input.toLowerCase().trim() == 'outlet' || input == 'glamstar')
      return input;
    if (input.toLowerCase().trim() == 'null') return BaseConnectService.NULL;
  }

  private async searchCategoryReference(reference: string, index: number) {
    if (!reference) return null;

    const category = await this.categoryRepository
      .find({
        filters: {
          reference: {
            operator: Where.EQUALS,
            value: reference.toString().trim()
          }
        }
      })
      .then((res) => res.data);

    if (!category.length)
      throw new Error(
        `Categoria do produto não encontrado na linha ${index + 2}!`
      );

    return category[0].id.toString();
  }

  private buildProductPrices(
    product: ProductBatchUpdateJSON,
    index: number
  ): ShopPrice {
    if (!(product.price || product.fullPrice || product.discountPercentage)) {
      return;
    }

    if (
      !this.hasValidPrices(
        product.price,
        product.fullPrice,
        product.discountPercentage
      )
    )
      throw new Error(
        `Para atualizar o preços, os campos de price, fullPrice e discountPercentage devem ser informados para o produto na linha ${
          index + 2
        }`
      );

    const hasInvalidCaracters =
      product.price?.toString().includes(',') ||
      product.fullPrice?.toString().includes(',') ||
      product.discountPercentage?.toString().includes(',');

    if (hasInvalidCaracters)
      throw new Error(
        `Produtos com preços fora do padrão na linha ${index + 2}!`
      );

    const subscriberPrice =
      product.price - (product.price * product.discountPercentage) / 100;
    const fullPriceDiscountPercentage =
      100 - (subscriberPrice / product.fullPrice) * 100;

    const prices = {
      ...(product.price && { price: product.price }),
      ...(product.fullPrice && { fullPrice: product.fullPrice }),
      ...(product.discountPercentage && {
        subscriberDiscountPercentage: product.discountPercentage,
        subscriberPrice: parseFloat(subscriberPrice.toFixed(2)),
        fullPriceDiscountPercentage: parseFloat(
          fullPriceDiscountPercentage.toFixed(2)
        )
      })
    };

    return prices;
  }

  private hasValidPrices(price, fullPrice, discountPercentage): boolean {
    if (
      (price && (!fullPrice || !discountPercentage)) ||
      (fullPrice && (!price || !discountPercentage)) ||
      (discountPercentage && (!fullPrice || !price))
    )
      return false;

    return true;
  }

  private async startImportation(
    chunks: Partial<Product>[][]
  ): Promise<Array<Partial<Product>>> {
    let successImportCount = 0;
    this.completeCount = 0;
    const updated: Array<Partial<Product>> = [];
    for (const chunk of chunks) {
      for (const product of chunk) {
        try {
          const p = await this.updateProduct(product);
          if (p) {
            updated.push(p);
            successImportCount++;
          }
        } catch (error) {
          this.errors.push(error);
        } finally {
          this.completeCount++;
        }
      }
    }
    if (this.errors.length === this.completeCount) {
      this.messageService.add({
        severity: 'warn',
        detail: `Não foi possível realizar a atualização`,
        summary: 'Erro'
      });
    } else {
      this.messageService.add({
        severity: 'success',
        detail: ` Foram atualizados ${successImportCount} produtos`,
        summary: 'Atualização concluída!'
      });
    }
    return updated?.length
      ? (
          await this.repository.find({
            filters: {
              id: { operator: Where.IN, value: updated.map((c) => c.id) }
            },
            limits: {
              limit: 9999,
              offset: 0
            }
          })
        ).data
      : [];
  }

  private getChunkedProductList(): Partial<Product>[][] {
    const itemsForUpload = [...this.itemsForUpload];

    return [...Array(Math.ceil(itemsForUpload.length / this.chunkSize))].map(
      () => itemsForUpload.splice(0, this.chunkSize)
    );
  }

  private async updateProduct(
    data: Partial<Product>
  ): Promise<Partial<Product>> {
    const productVariante = (
      await this.variantRepository.find({
        filters: { EAN: data.EAN },
        limits: { limit: 1 }
      })
    ).data.shift();
    let productMain = (
      await this.repository.find({
        filters: { EAN: data.EAN },
        limits: { limit: 1 }
      })
    ).data.shift();
    const updates = [];

    if (productVariante) {
      const variant = Object.entries({
        price: data.price,
        ...(data.sku && { sku: data.sku }),
        ...(data.costPrice && { costPrice: data.costPrice }),
        ...(data.weight && { weight: data.weight }),
        ...(data.stock && { stock: data.stock })
      }).reduce(
        (iProduct, [key, value]) =>
          ![undefined, null].includes(value)
            ? { ...iProduct, [key]: value }
            : iProduct,
        {}
      );
      updates.push(
        this.variantRepository.update(
          Variant.toInstance({
            id: productVariante.id,
            ...variant
          })
        )
      );
      if (!productMain && productVariante.productId) {
        productMain = await this.repository.get({
          id: productVariante.productId
        });
      }
    }
    if (productMain) {
      updates.push(
        this.repository.update(
          Product.toInstance({
            id: productMain?.id,
            ...data
          })
        )
      );
    }

    if (!updates.length) {
      throw { message: `EAN ${data.EAN} não encontrado.` };
    }

    await Promise.all(updates);
    return productMain;
  }
}
