<app-table *ngIf="cols"
  [cols]="cols"
  pageFunctionName="getProductStockNotificationReport"
  [service]="shoppingProductService"
  modelName="Avise-me"
  sortField="name"
  [sortOrder]="-1"
  [defaultRows]="10"
  [changeColumns]="true"
  [addButton]="false" />
