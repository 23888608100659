<ng-container *ngIf="category">
  <div class="page-title">Detalhes da categoria - {{ category?.name }}</div>
  <div class="category-form">
    <div class="card">
      <p-tabView
        [activeIndex]="selectedTab"
        (activeIndexChange)="tabChanged($event)"
      >
        <p-tabPanel header="Informações">
          <app-shop-category-form
            [category]="category"
            (categoryUpdated)="categoryUpdated($event)"
          />
        </p-tabPanel>

        <p-tabPanel header="Imagens">
          <app-shop-category-images *ngIf="category" [category]="category" />
        </p-tabPanel>

        <p-tabPanel *ngIf="category">
          <ng-template pTemplate="header">
            <span class="p-tabview-title">
              Produtos
              <ng-containter *ngIf="!categoryProducts"
                >&nbsp;<i class="pi pi-spinner pi-spin"></i
              ></ng-containter>
              <ng-container *ngIf="categoryProducts">
                ({{
                  categoryProducts?.length || 0 | number : '1.0-0'
                }})</ng-container
              >
            </span>
          </ng-template>
          <app-table
            [lazy]="false"
            *ngIf="categoryProducts"
            [elements]="categoryProducts"
            [cols]="productCols"
            modelName="produtos"
            tableTitle=" "
            sortField="name"
            [sortOrder]="-1"
            [defaultRows]="10"
            [addButton]="false"
            [changeColumns]="true"
          />
        </p-tabPanel>

        <p-tabPanel header="Mais relevantes">
          <p-accordion
            class="w-full"
            expandIcon="pi pi-plus"
            collapseIcon="pi pi-minus"
            [activeIndex]="accordionIndex"
            (activeIndexChange)="accordionIndex = $event"
            *ngIf="categoryProducts"
          >
            <p-accordionTab
              class="w-full"
              expandIcon="pi pi-plus"
              collapseIcon="pi pi-minus"
              *ngFor="let shop of mostRelevantShops; let i = index"
            >
              <ng-template pTemplate="header">
                <span class="accordion-header">
                  <span> {{ shop.label }} </span>
                  <ng-container
                    *ngIf="accordionIndex !== i && category.mostRelevants"
                  >
                    <p-badge
                      [value]="category.mostRelevants[shop.value]?.length || 0"
                    />
                    <div class="products">
                      <div
                        class="product"
                        *ngFor="
                          let product of mostRelevant(shop.value)
                            | slice : 0 : 5
                        "
                      >
                        <img
                          class="product-image"
                          [src]="product.images[0]"
                          [alt]="product.name"
                        />
                        <span class="product-name">{{ product.name }}</span>
                      </div>
                    </div>
                  </ng-container>
                </span>
              </ng-template>
              <form class="form-b4a" (submit)="saveMostRelevants(shop.value)">
                <ng-container *ngIf="categoryProducts?.length">
                  <span class="full-w">
                    <p-pickList
                      [metaKeySelection]="true"
                      [source]="productsForSelection[shop.value]"
                      [target]="mostRelevants[shop.value]"
                      sourceHeader="Produtos da categoria"
                      targetHeader="Mais relevantes"
                      [dragdrop]="true"
                      [responsive]="true"
                      [sourceStyle]="{ height: '27rem' }"
                      [targetStyle]="{ height: '27rem' }"
                      filterBy="name,brand,EAN,sku"
                      sourceFilterPlaceholder="Pesquisar pelo nome, marca, EAN ou SKU"
                      targetFilterPlaceholder="Pesquisar pelo nome, marca, EAN ou SKU"
                      breakpoint="1200px"
                      [showSourceControls]="false"
                      *ngIf="productsForSelection[shop.value]"
                    >
                      <ng-template let-product pTemplate="item">
                        <div class="form-b4a_item-container">
                          <img
                            class="w-4rem shadow-2 flex-shrink-0 border-round"
                            [src]="product.images[0]"
                            [alt]="product.name"
                          />
                          <div class="form-b4a_item-container_info">
                            <span class="form-b4a_item-container_info_title">{{
                              product.name
                            }}</span>
                            <div
                              class="form-b4a_item-container_info_description"
                            >
                              <div
                                class="form-b4a_item-container_info_description_brand"
                              >
                                <span> Marca: {{ product.brand }} </span>
                                <span>
                                  Estoque: {{ product.stock.quantity }}
                                </span>
                                <span>
                                  Nota:
                                  {{
                                    product.rate
                                      ? product.rate
                                      : 'Sem avaliações'
                                  }}
                                </span>
                                <span *ngIf="product.EAN"
                                  >EAN: {{ product.EAN }}</span
                                >
                              </div>

                              <div
                                class="form-b4a_item-container_info_description_price"
                              >
                                <span *ngIf="product.sku"
                                  >SKU: {{ product.sku }}</span
                                >
                                <span>
                                  Preço:
                                  {{
                                    product.price.price | currency : 'BRL'
                                  }}</span
                                >
                                <span *ngIf="product.price.subscriberPrice">
                                  Preço assinante:
                                  {{
                                    product.price.subscriberPrice
                                      | currency : 'BRL'
                                  }}
                                </span>
                                <span
                                  >Genero:
                                  {{
                                    (product.gender
                                      ? product.gender
                                      : 'sem informação'
                                    ) | titlecase
                                  }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </p-pickList>
                  </span>
                  <span
                    class="p-input-checkbox"
                    *ngIf="category?.shops?.length > 1"
                  >
                    <p-checkbox
                      [binary]="true"
                      [formControl]="copyMostRelevants"
                      [inputId]="'copyMostRelevants_' + shop.value"
                    />
                    <label [for]="'copyMostRelevants_' + shop.value">
                      Mesma ordenação em todas as lojas
                    </label>
                    <small>
                      Todas as lojas possuirão a mesma ordenação dos mais
                      relevantes
                    </small>
                  </span>
                  <div class="form-footer">
                    <div class="info">
                      <div *ngIf="category">
                        <p>
                          *Última alteração em:
                          {{ category.updatedAt | date : 'short' }}
                        </p>
                      </div>
                    </div>
                    <div class="action">
                      <p-button
                        type="submit"
                        pRipple
                        label="Salvar"
                        severity="primary"
                        [disabled]="buttonDisabled(shop.value)"
                      >
                      </p-button>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="!categoryProducts?.length">
                  Não existem produtos na categoria
                </ng-container>
              </form>
            </p-accordionTab>
          </p-accordion>
          <ng-container *ngIf="!categoryProducts">
            Buscando produtos, aguarde...
          </ng-container>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!category">
  <div class="category-form">
    <div class="page-title">Criar nova categoria</div>
    <app-shop-category-form />
  </div>
</ng-container>
<p-toast />
<p-confirmPopup />
