<app-consolidated-dashboard
  [glamSubscriptions]="subscribersRealtimeTotal"
  *ngIf="subscribersRealtimeTotal() && ready && index() === -1"
  [class.slide-right]="index() === -1"
/>
<ng-container>
  <app-analytics-dashboard
    [subscribersRealtimeTotal]="subscribersRealtimeTotal"
    [defaultSubscriptionId]="0"
    [class.slide-right]="index() === 1"
    [show]="signals[0]"
  />
</ng-container>
