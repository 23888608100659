import { Component, inject, Input } from '@angular/core';
import {
  PersonDetail,
  SubscriberControllerService,
  SubscriberInfo
} from 'src/app/admin-api';
import { Address } from 'src/app/admin-api/model/address';

@Component({
  selector: 'app-address-card',
  templateUrl: './address-card.component.html'
})
export class AddressCardComponent {
  @Input()
  address: Address | undefined;

  @Input()
  subscribers: Array<SubscriberInfo> = [];

  @Input()
  person: PersonDetail | undefined;

  public subscriberControllerService = inject(SubscriberControllerService);

  isAddressVinculatedInSubscriber(): boolean {
    return this.subscribers.some(
      (sub) => sub.addressIdActiveOnSubscriber === this.address.addressId
    );
  }

  isAddressVinculatedInPerson(): boolean {
    return this.person.addressId === this.address.addressId;
  }
}
