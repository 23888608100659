import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  inject
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ShopDescription } from '@infrab4a/connect';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Editor } from 'primeng/editor';
import Quill from 'quill';
import { Product } from 'src/app/admin-api';
import { ShopProductService } from 'src/app/connect-api/api/shop/shop-product.service';
import { ShopProduct } from 'src/app/connect-api/models/ShopProduct';
import { DescriptionField, getDescriptionField } from 'src/app/models';
import { ProductDetailsComponent } from 'src/app/pages/products/product-details/product-details.component';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';
import { ProductDiffModalComponent } from '../../product-diff-modal/product-diff-modal.component';
import { ShopProductDescriptionReviewComponent } from '../shop-product-description-review/shop-product-description-review.component';

@Component({
  selector: 'app-shop-product-description-form',
  templateUrl: './shop-product-description-form.component.html',
  styleUrl: './shop-product-description-form.component.scss',
  providers: [DialogService]
})
export class ShopProductDescriptionFormComponent implements OnInit, OnChanges {
  @Input({ required: true }) product: Partial<ShopProduct>;
  @Input() subscriptionProduct: Product;
  @Output() productUpdated = new EventEmitter();

  @ViewChild('description') editorDescription: Editor;
  @ViewChild('differentials') editorDifferentials: Editor;
  @ViewChild('whoMustUse') editorWhoMustUse: Editor;
  @ViewChild('howToUse') editorHowToUse: Editor;
  @ViewChild('ingredients') editorIngredients: Editor;
  @ViewChild('brand') editorAboutBrand: Editor;
  @ViewChild('purpose') editorPurpose: Editor;

  private productService = inject(ShopProductService);
  private messageService = inject(MessageService);
  private dialog = inject(DialogService);

  form = new FormGroup({
    description: new FormGroup({
      description: new FormControl<string>(null, Validators.required),
      differentials: new FormControl<string>(null),
      whoMustUse: new FormControl<string>(null),
      howToUse: new FormControl<string>(null),
      brand: new FormControl<string>(null),
      ingredients: new FormControl<string>(null),
      purpose: new FormControl<string>(null)
    }),
    video: new FormControl<string>(null),
    id: new FormControl<string>(null, Validators.required)
  });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['product']) {
      this.form.patchValue(this.product);
    }
  }

  ngOnInit(): void {
    this.form.patchValue(this.product);
  }

  showReview(): void {
    FormUtil.trimAllStrings(this.form);
    this.form.updateValueAndValidity();
    if (this.form.valid) {
      this.dialog
        .open(ShopProductDescriptionReviewComponent, {
          data: {
            product: { ...this.form.value }
          },
          width: '90%',
          header: this.product.name,
          maximizable: true
        })
        .onClose.subscribe(async (submit) => {
          if (submit) await this.submit();
        });
    }
  }

  async submit(): Promise<void> {
    try {
      LoaderService.showLoader();
      const body = { ...this.form.value } as Partial<ShopProduct>;

      body.description = {
        description: (this.editorDescription.getQuill() as Quill)
          .getText()
          .trim()
          ? this.editorDescription.value
          : '',
        brand: (this.editorAboutBrand.getQuill() as Quill).getText().trim()
          ? this.editorAboutBrand.value
          : '',
        differentials: (this.editorDifferentials.getQuill() as Quill)
          .getText()
          .trim()
          ? this.editorDifferentials.value
          : '',
        howToUse: (this.editorHowToUse.getQuill() as Quill).getText().trim()
          ? this.editorHowToUse.value
          : '',
        whoMustUse: (this.editorWhoMustUse.getQuill() as Quill).getText().trim()
          ? this.editorWhoMustUse.value
          : '',
        ingredients: (this.editorIngredients.getQuill() as Quill)
          .getText()
          .trim()
          ? this.editorIngredients.value
          : '',
        purpose: (this.editorPurpose.getQuill() as Quill).getText().trim()
          ? this.editorPurpose.value
          : ''
      } as ShopDescription;
      await this.productService.updateProduct(body);
      this.productUpdated.emit(true);
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Produto atualizado com sucesso.'
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async syncInfo(column: DescriptionField): Promise<void> {
    this.dialog
      .open(ProductDiffModalComponent, {
        data: {
          product: this.subscriptionProduct,
          shopProduct: this.product,
          column
        },
        width: '60%',
        height: '90vh',
        header: 'Sincronizar ' + this.product.name,
        maximizable: true
      })
      .onClose.subscribe(async (success) => {
        if (success) {
          LoaderService.showLoader();
          this.productUpdated.emit();
          this.messageService.add({
            summary: 'Sucesso',
            severity: 'success',
            detail: `Campo ${column.label} sincronizado com sucesso.`
          });
          LoaderService.showLoader(false);
        }
      });
  }

  hasSyncInfo(value: string) {
    return ProductDetailsComponent.hasSyncInfo(
      this.descriptionField(value),
      this.subscriptionProduct,
      { ...this.form.value, filters: [] } as Partial<ShopProduct>
    );
  }

  descriptionField(value: string) {
    const field = getDescriptionField(value);
    return field;
  }
}
