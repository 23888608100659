/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PageableFilter {
  field?: string;
  value?: any | Array<any>;
  condition?:
    | 'equals'
    | 'notEquals'
    | 'contains'
    | 'startsWith'
    | 'gt'
    | 'lt'
    | 'gte'
    | 'between'
    | 'in'
    | 'notIn'
    | 'lte';
  fieldType?:
    | 'text'
    | 'number'
    | 'date'
    | 'link'
    | 'button'
    | 'boolean'
    | 'inputSwitch'
    | 'checkbox'
    | 'currency'
    | 'image'
    | 'status'
    | 'formattedInteger'
    | 'formattedNumber'
    | 'percentage'
    | 'inputNumber';
  typeValue?: any;
}
