import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConfirmationService, PrimeIcons } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CreditCardSummary } from 'src/app/admin-api';
import { LoaderService } from 'src/app/services/loader.service';
import { DisableRecurrenceModalComponent } from '../disable-recurrence-modal/disable-recurrence-modal.component';
import { TableActionButton, TableColumn } from '../table';

@Component({
  selector: 'app-person-credit-cards',
  templateUrl: './person-credit-cards.component.html',
  styleUrls: ['./person-credit-cards.component.scss'],
  providers: [ConfirmationService]
})
export class PersonCreditCardsComponent {
  @Input()
  creditCards: Array<CreditCardSummary & { flag: string }> | undefined;

  @Input()
  personId: number | undefined;

  @Input()
  isMobile?: boolean;

  @Output()
  cardRemoved = new EventEmitter<void>();

  cardCols: Array<TableColumn> = [
    new TableColumn('Id', 'creditCardId', false, 'number'),
    new TableColumn('Bandeira', 'flag', false, 'image'),
    new TableColumn('Final', 'lastDigits', false, 'text'),
    new TableColumn('Nome', 'nameOnCard', false, 'text'),
    new TableColumn('Assinatura', 'glamId', false, 'text'),
    new TableColumn('Recorrência', 'recurrence', false, 'text'),
    new TableColumn('Data cadastro', 'dateCreated', false, 'date'),
    new TableColumn('Ação', '', false, 'button')
  ];
  actionButtons: Array<TableActionButton> = [
    new TableActionButton(
      '',
      'confirmDeleteCard',
      PrimeIcons.BAN,
      this.isDeleteCard,
      '',
      'Remover cartão',
      'bottom',
      true,
      true,
      'danger'
    )
  ];
  constructor(private dialog: DialogService) {}

  confirmDeleteCard(creditCard: CreditCardSummary): void {
    let width = '100%';
    if (!this.isMobile) {
      width = '500px';
    }
    this.dialog
      .open(DisableRecurrenceModalComponent, {
        closable: true,
        header: 'Desabilitar recorrência',
        data: {
          subscriberId: creditCard?.subscriberId
        },
        width
      })
      .onClose.subscribe(async (cards: Array<CreditCardSummary>) => {
        if (cards) {
          // for (let index = 0; index < cards.length; index++) {
          //   this.creditCards[index] = {
          //     ...cards[index],
          //     flag: this.creditCards[index].flag
          //   };
          // }
          this.creditCards = cards.map((c, i) => ({
            ...c,
            flag: this.creditCards[i].flag
          }));
          LoaderService.showLoader(false);
        }
      });
    // this.confirmationService.confirm({
    //   message: `Deseja remover o cartão final ${creditCard.lastDigits} da assinatura?`,
    //   target: $event.target,
    //   acceptLabel: 'Sim',
    //   rejectLabel: 'Não',
    //   acceptButtonStyleClass: 'p-button-danger',
    //   rejectButtonStyleClass: 'p-button-primary',
    //   accept: async () => {
    //     LoaderService.showLoader();
    //     try {
    //       await this.deleteCard(creditCard.creditCardId as number);
    //       delete this.creditCards;
    //       this.cardRemoved.emit();
    //     } catch (error: any) {
    //       AppDialogService.showErrorDialog(error);
    //     }
    //     LoaderService.showLoader(false);
    //   }
    // });
  }

  isDeleteCard(card: CreditCardSummary): boolean {
    return card.subscriberId && card.recurrenceStatusId !== 0;
  }
}
