import { DatePipe } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
  inject
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  CheckoutTypes,
  Coupon,
  CouponTypes,
  Exclusivities,
  Shops
} from '@infrab4a/connect';
import { ClubeDaMensPlan } from '@infrab4a/connect/src/domain/shopping/models/coupons/enums/coupon-club-mens.enum';
import { MessageService } from 'primeng/api';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { ChipsAddEvent } from 'primeng/chips';
import { InfluencerDetail, PersonDetail } from 'src/app/admin-api';
import { ShopCouponService } from 'src/app/connect-api/api/shop/shop-coupon.service';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-shop-coupon-form',
  templateUrl: './shop-coupon-form.component.html',
  styleUrl: './shop-coupon-form.component.scss',
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService, DatePipe]
})
export class ShopCouponFormComponent implements OnInit {
  private shopCouponService = inject(ShopCouponService);
  private router: Router = inject(Router);
  private messageService = inject(MessageService);
  private datePipe = inject(DatePipe);

  @Input() couponId: string;
  @Input() coupon: Partial<Coupon>;
  @Input() person: PersonDetail;
  @Input() influencer: InfluencerDetail;

  protected today: Date;
  protected form = new FormGroup({
    id: new FormControl<string>(
      { value: null, disabled: true },
      Validators.required
    ),
    category: new FormControl<string>(null, Validators.required),
    discount: new FormGroup({
      subscriber: new FormGroup({
        type: new FormControl<CouponTypes>(CouponTypes.ABSOLUTE, [
          Validators.required,
          Validators.min(1),
          Validators.max(2)
        ]),
        value: new FormControl<number>(0, [
          Validators.required,
          Validators.min(0.01)
        ])
      }),
      non_subscriber: new FormGroup({
        type: new FormControl<CouponTypes>(CouponTypes.ABSOLUTE, [
          Validators.required,
          Validators.min(1),
          Validators.max(2)
        ]),
        value: new FormControl<number>(0, [
          Validators.required,
          Validators.min(0.01)
        ])
      }),
      subscription: new FormGroup({
        type: new FormControl<CouponTypes>(CouponTypes.ABSOLUTE, [
          Validators.required,
          Validators.min(1),
          Validators.max(2)
        ]),
        value: new FormControl<number>(0, [
          Validators.required,
          Validators.min(0.01)
        ])
      })
    }),
    useLimits: new FormGroup({
      subscriber: new FormGroup({
        unlimited: new FormControl<boolean>(true),
        total: new FormControl<number>({ value: null, disabled: true }, [
          Validators.required,
          Validators.min(1)
        ]),
        limitedPerUser: new FormControl<boolean>(true),
        firstOrder: new FormControl<boolean>(false)
      }),
      non_subscriber: new FormGroup({
        unlimited: new FormControl<boolean>(true),
        total: new FormControl<number>({ value: null, disabled: true }, [
          Validators.required,
          Validators.min(1)
        ]),
        limitedPerUser: new FormControl<boolean>(true),
        firstOrder: new FormControl<boolean>(false)
      }),
      subscription: new FormGroup({
        unlimited: new FormControl<boolean>(true),
        total: new FormControl<number>({ value: null, disabled: true }, [
          Validators.required,
          Validators.min(1)
        ]),
        limitedPerUser: new FormControl<boolean>(true),
        firstOrder: new FormControl<boolean>(false)
      })
    }),
    userExclusiveEmail: new FormControl<string[]>(
      { value: [], disabled: true },
      [Validators.required, Validators.minLength(1)]
    ),
    nickname: new FormControl<string>(null, Validators.required),
    beginAt: new FormControl<Date>(null, [Validators.required]),
    expiresIn: new FormControl<Date>(null),
    shopAvailability: new FormControl<Shops>(Shops.ALL, Validators.required),
    plan: new FormControl<ClubeDaMensPlan>(
      { value: null, disabled: true },
      Validators.required
    ),
    minSubTotalValue: new FormControl<number>(0, Validators.required),
    active: new FormControl(true),
    checkoutType: new FormControl<CheckoutTypes>(CheckoutTypes.ALL, [
      Validators.required,
      Validators.min(1),
      Validators.max(3)
    ]),
    exclusivityType: new FormControl<Exclusivities[]>(null, [
      Validators.required,
      Validators.min(1),
      Validators.max(6)
    ]),
    personId: new FormControl({ value: null, disabled: true })
  });
  stores = [
    { value: 'Glamshop', displayName: `Glam` },
    { value: 'mensmarket', displayName: `Men's Market` },
    { value: 'ALL', displayName: `Todos` }
  ];

  checkoutTypes = [
    { value: 1, displayName: `Lojas` },
    { value: 2, displayName: `Assinatura` },
    { value: 3, displayName: `Todos` }
  ];

  discountTypes = [
    { value: 1, displayName: `Absoluto` },
    { value: 2, displayName: `Porcentagem` }
  ];

  couponCategories = [
    { value: 'Reembolso', displayName: `Reembolso` },
    { value: 'Desculpas', displayName: `Desculpas` },
    { value: 'Glampartner', displayName: `Glampartner` },
    { value: 'Glamqueens', displayName: `Glamqueens` },
    { value: 'Glamgirls', displayName: `Glamgirls` },
    { value: 'Glamdiva', displayName: `Glamdiva` },
    { value: 'Impulsionamento', displayName: `Impulsionamento` },
    { value: 'Paid Media', displayName: `Paid Media` },
    { value: 'Organic', displayName: `Organic` },
    { value: 'Direct', displayName: `Direct` },
    { value: 'CRM', displayName: `CRM` },
    { value: 'LOJAUNICO', displayName: `Loja único` },
    { value: 'Outros', displayName: `Outros` }
  ];

  excluisivities = [
    { label: 'Todos os usuários', value: 1 },
    { label: 'Apenas assinantes inativos (ex assinantes)', value: 5 },
    { label: 'Apenas assinantes ativos', value: 4 },
    { label: 'Somente colaboradores', value: 3 },
    { label: 'Não assinantes', value: 6 },
    { label: 'Usuário(s) específicos', value: 2 }
  ];
  chipRegex: any = /\r|\n|;|,/;

  plans = [
    { label: 'Prime', value: 'PRIME' },
    { label: 'Prime mensal', value: 'PRIME_MENSAL' },
    { label: 'Select', value: 'SELECT' },
    { label: 'Select mensal', value: 'SELECT_MENSAL' }
  ];

  ngOnInit() {
    this.today = new Date(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
    this.form.controls['expiresIn'].valueChanges.subscribe((value) => {
      setTimeout(() => {
        this.checkDateValid(value);
      });
    });
    this.form.controls['beginAt'].valueChanges.subscribe(() => {
      setTimeout(() => {
        this.checkDateValid();
      });
    });
    if (this.coupon) this.form.controls['id'].enable();
    this.form.patchValue({
      ...this.coupon,
      active: !!this.coupon?.active,
      exclusivityType: this.coupon?.exclusivityType,
      expiresIn:
        this.coupon?.expiresIn &&
        this.coupon?.expiresIn instanceof Date &&
        !Number.isNaN(this.coupon?.expiresIn.getTime())
          ? this.coupon?.expiresIn
          : null,
      beginAt:
        this.coupon?.beginAt &&
        this.coupon?.beginAt instanceof Date &&
        !Number.isNaN(this.coupon?.beginAt.getTime())
          ? this.coupon?.beginAt
          : this.today
    });
    this.checkRestrictions();
  }

  protected async save() {
    if (this.coupon) {
      const request: Partial<Coupon> = {
        ...this.form.value,
        id: this.couponId,
        expiresIn: this.form.value.expiresIn?.getTime() as any,
        discount: this.form.value.discount as any,
        useLimits: this.form.value.useLimits as any
      };
      this.fixDisabledLimits(request);

      try {
        LoaderService.showLoader(true);
        await this.shopCouponService.updateCoupon(request as Partial<Coupon>);
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Cupom atualizado com sucesso.'
        });
      } catch (error) {
        AppDialogService.showErrorDialog(error);
      } finally {
        LoaderService.showLoader(false);
      }
    } else {
      try {
        LoaderService.showLoader(true);
        const coupon: Partial<Coupon> = {
          ...this.form.value,
          productsCategories: [],
          discount: this.form.value.discount as any,
          useLimits: this.form.value.useLimits as any
        };
        this.fixDisabledLimits(coupon);
        const result = await this.shopCouponService.saveCoupon(coupon);
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Cupom criado com sucesso.'
        });
        this.router.navigate([`/marketing/shop-coupons/${result.id}`]);
      } catch (error) {
        AppDialogService.showErrorDialog(error);
      } finally {
        LoaderService.showLoader(false);
      }
    }
  }

  checkRestrictions(): void {
    if (
      this.form.value.exclusivityType?.includes(Exclusivities.SPECIFIC_USER)
    ) {
      this.form.controls['userExclusiveEmail'].enable();
    } else {
      this.form.controls['userExclusiveEmail'].setValue([]);
      this.form.controls['userExclusiveEmail'].disable();
    }
    this.checkExclusivities();
  }

  addEmail(event?: ChipsAddEvent) {
    if (event?.value) {
      const index = this.form.value.userExclusiveEmail.findIndex(
        (t) => t === (event.value as string).toLowerCase()
      );
      if (index >= 0 && index < this.form.value.userExclusiveEmail.length - 1) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Atenção',
          detail: `E-mail já informado '${this.form.value.userExclusiveEmail[index]}'`
        });
        this.form.controls['userExclusiveEmail'].setValue(
          this.form.value.userExclusiveEmail.slice(
            0,
            this.form.value.userExclusiveEmail.length - 1
          )
        );
      } else if (
        !(event.value as string)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      ) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Atenção',
          detail: `E-mail inválido '${this.form.value.userExclusiveEmail[index]}'`
        });
        this.form.controls['userExclusiveEmail'].setValue(
          this.form.value.userExclusiveEmail.slice(
            0,
            this.form.value.userExclusiveEmail.length - 1
          )
        );
      }
    }
    this.form.controls['userExclusiveEmail'].setValue(
      this.form.value.userExclusiveEmail.map((t) => t.toLowerCase())
    );
  }

  updateQuantity(
    formControlName: 'subscriber' | 'non_subscriber' | 'subscription',
    event: CheckboxChangeEvent
  ): void {
    if (!event.checked) {
      this.form.controls.useLimits.get(formControlName).get('total').enable();
      this.form.controls.useLimits
        .get(formControlName)
        .get('total')
        .setValue(
          this.form.getRawValue().useLimits[formControlName].total || 0
        );
    } else
      this.form.controls.useLimits.get(formControlName).get('total').disable();
  }

  checkExclusivities(): void {
    if (this.useLimit) {
      this.form.controls.useLimits.controls['non_subscriber'].enable();
      this.updateQuantity('non_subscriber', {
        checked: this.form.value.useLimits?.non_subscriber?.unlimited
      });
    } else {
      this.form.controls.useLimits.controls['non_subscriber'].disable();
    }
    if (this.useLimitSubscribers) {
      this.form.controls.useLimits.controls['subscriber'].enable();
      this.updateQuantity('subscriber', {
        checked: this.form.value.useLimits?.subscriber?.unlimited
      });
    } else {
      this.form.controls.useLimits.controls['subscriber'].disable();
    }
    if (this.useLimitSubscription) {
      this.form.controls.useLimits.controls['subscription'].enable();
      this.updateQuantity('subscription', {
        checked: this.form.value.useLimits?.subscription?.unlimited
      });
    } else {
      this.form.controls.useLimits.controls['subscription'].disable();
    }
    if (
      this.form.controls.checkoutType.valid &&
      (this.form.value.checkoutType === 2 || this.form.value.checkoutType === 3)
    ) {
      this.form.controls.plan.enable();
    } else {
      this.form.controls.plan.disable();
    }
    if (
      this.form.controls['checkoutType'].valid &&
      this.form.value.checkoutType !== CheckoutTypes.SUBSCRIPTION
    ) {
      this.form.controls['discount'].controls['subscriber'].enable();
    } else {
      this.form.controls['discount'].controls['subscriber'].disable();
    }
    if (
      this.form.controls['checkoutType'].valid &&
      this.form.value.checkoutType !== CheckoutTypes.SUBSCRIPTION
    ) {
      this.form.controls['discount'].controls['non_subscriber'].enable();
    } else {
      this.form.controls['discount'].controls['non_subscriber'].disable();
    }
    if (
      this.form.controls['checkoutType'].valid &&
      this.form.value.checkoutType !== CheckoutTypes.ECOMMERCE
    ) {
      this.form.controls['discount'].controls['subscription'].enable();
    } else {
      this.form.controls['discount'].controls['subscription'].disable();
    }
  }

  checkDateValid(value?: Date): void {
    if (!value) value = this.form.value.expiresIn;
    if (value)
      if (value.getTime() < this.today.getTime()) {
        setTimeout(() => {
          this.form.controls['expiresIn'].setErrors({
            invalidDate: 'Menor que a data atual'
          });
          this.form.controls['expiresIn'].markAsTouched();
          this.form.controls['expiresIn'].markAsDirty();
        }, 500);
      } else if (
        new Date(
          this.datePipe.transform(value.getTime(), 'yyyy-MM-dd')
        ).getTime() < this.form.value.beginAt.getTime()
      ) {
        setTimeout(() => {
          this.form.controls['expiresIn'].setErrors({
            invalidDate: `Deve ser maior que a data de início ${this.datePipe.transform(
              this.form.value.beginAt,
              'dd/MM/yyyy'
            )}`
          });
          this.form.controls['expiresIn'].markAsTouched();
          this.form.controls['expiresIn'].markAsDirty();
        }, 500);
      } else {
        setTimeout(() => {
          this.form.controls['expiresIn'].setErrors(null);
        }, 500);
      }
  }

  fixDisabledLimits(coupon: Partial<Coupon>) {
    Object.keys(this.form.controls.useLimits.controls).forEach((c) => {
      if (this.form.controls.useLimits.get(c).disabled) {
        coupon.useLimits[c] = {
          unlimited: false,
          total: 0,
          limitedPerUser: false,
          firstOrder: false
        };
      }
    });
  }

  get nonSubscribersOrAllUsers() {
    return (
      this.form.controls.exclusivityType.valid &&
      (this.form.value.exclusivityType?.includes(
        Exclusivities.NON_SUBSCRIBER
      ) ||
        this.form.value.exclusivityType?.includes(Exclusivities.ALL_USERS))
    );
  }

  get useLimitSubscribers() {
    return (
      this.form.controls.checkoutType.valid &&
      this.form.controls.exclusivityType.valid &&
      (this.form.value.checkoutType === CheckoutTypes.ECOMMERCE ||
        this.form.value.checkoutType === CheckoutTypes.ALL) &&
      (this.form.value.exclusivityType?.includes(
        Exclusivities.ACTIVE_SUBSCRIBER
      ) ||
        this.form.value.exclusivityType?.includes(Exclusivities.ALL_USERS))
    );
  }

  get useLimitSubscription() {
    return (
      this.form.value.checkoutType === CheckoutTypes.ALL ||
      this.form.value.checkoutType === CheckoutTypes.SUBSCRIPTION
    );
  }

  get useLimit() {
    return (
      (this.form.value.checkoutType === CheckoutTypes.ALL ||
        this.form.value.checkoutType === CheckoutTypes.ECOMMERCE) &&
      !(
        !this.form.value.exclusivityType?.includes(Exclusivities.ALL_USERS) &&
        !this.form.value.exclusivityType?.includes(
          Exclusivities.NON_SUBSCRIBER
        ) &&
        !this.form.value.exclusivityType?.includes(
          Exclusivities.INACTIVE_SUBSCRIBER
        ) &&
        !this.form.value.exclusivityType?.includes(
          Exclusivities.COLLABORATORS
        ) &&
        !this.form.value.exclusivityType?.includes(Exclusivities.SPECIFIC_USER)
      ) &&
      (this.form.value.exclusivityType?.includes(
        Exclusivities.INACTIVE_SUBSCRIBER
      ) ||
        this.form.value.exclusivityType?.includes(
          Exclusivities.NON_SUBSCRIBER
        ) ||
        !this.form.value.exclusivityType?.includes(
          Exclusivities.ACTIVE_SUBSCRIBER
        ))
    );
  }
}
