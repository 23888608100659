/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import { SubscriberControllerService } from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-disable-recurrence-modal',
  templateUrl: './disable-recurrence-modal.component.html'
})
export class DisableRecurrenceModalComponent implements OnInit {
  private config = inject(DynamicDialogConfig);
  public ref = inject(DynamicDialogRef);
  private subscriberService = inject(SubscriberControllerService);

  subscriberId: number = this.config.data.subscriberId;
  formGroup = new FormGroup({
    subscriberId: new FormControl(this.subscriberId || 0, [
      Validators.required,
      Validators.min(1)
    ]),
    message: new FormControl(undefined, [
      Validators.required,
      Validators.minLength(6)
    ])
  });
  removeCard = this.config.data.removeCard || false;

  ngOnInit() {
    if (!this.subscriberId)
      throw 'DisableRecurrenceModalComponent: please provide subscriberId';
  }

  async submit(): Promise<void> {
    if (this.formGroup.valid) {
      LoaderService.showLoader();
      try {
        let result;
        if (!this.removeCard)
          result = await lastValueFrom(
            this.subscriberService
              .disableSubscriberRecurrence(this.formGroup.value)
              .pipe(map((data) => data.result))
          );
        else
          result = await lastValueFrom(
            this.subscriberService
              .deleteCreditCard(this.formGroup.value)
              .pipe(map((data) => data.result))
          );
        this.ref.close(result);
      } catch (error: any) {
        LoaderService.showLoader(false);
        AppDialogService.showErrorDialog(error);
      }
    }
  }
}
