import { Component, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import {
  IncorrectEndEdition,
  ItOpsControllerService,
  SubscriberProblemGroupDetail
} from 'src/app/admin-api';
import { TableColumn, TableComponent } from 'src/app/components/table';
import { IncorrectEndEditionModalComponent } from './incorrect-end-edition-modal/incorrect-end-edition-modal.component';

@Component({
  selector: 'app-incorrect-end-edition',
  templateUrl: './incorrect-end-edition.component.html',
  styleUrls: ['./incorrect-end-edition.component.scss'],
  providers: [
    MessageService,
    DialogService,
    ConfirmationService,
    ItOpsControllerService
  ]
})
export class IncorrectEndEditionComponent {
  @ViewChild(TableComponent) table: TableComponent;

  columns: Array<TableColumn> = [
    new TableColumn('', '', false, 'checkbox'),
    new TableColumn(
      'SubscriberId',
      'subscriberId',
      true,
      'number',
      '/users/subscribers/',
      'subscriberId'
    ),
    new TableColumn('Next Edition', 'nextEdition', true, 'number'),
    new TableColumn('End Edition', 'endEdition', true, 'number'),
    new TableColumn('EditionId', 'editionId', true, 'number'),
    new TableColumn('Next Payment Date', 'nextPaymentDate', true, 'number'),
    new TableColumn(
      'Subscriber Billing Status',
      'subscriberBillingStatus',
      true,
      'number'
    )
  ];
  ready = true;
  constructor(
    public itOpsService: ItOpsControllerService,
    private dialog: DialogService,
    private messageService: MessageService
  ) {}

  openActionModal(recurrences: Array<IncorrectEndEdition>): void {
    this.dialog
      .open(IncorrectEndEditionModalComponent, {
        height: '65vh',
        header: 'End Editions Diferentes',
        data: {
          recurrences
        }
      })
      .onClose.subscribe(async (data: Array<SubscriberProblemGroupDetail>) => {
        if (data) {
          this.ready = false;
          setTimeout(() => {
            this.messageService.add({
              severity: 'success',
              summary: 'Sucesso',
              detail: recurrences.length + ' marcado(s) como resolvido(s)'
            });
            this.ready = true;
          }, 1000);
        }
      });
  }
}
