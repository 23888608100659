<form class="modal-form" (submit)="submit()" [formGroup]="formGroup">
  <span class="p-float-label">
    <input type="text" pInputText formControlName="message" id="message" />
    <label for="message">Descreva o motivo</label>
  </span>
  <span class="buttons">
    <p-button
      label="Voltar"
      size="small"
      severity="primary"
      (onClick)="ref.close()"
      type="button"
    />
    <p-button
      label="Desabilitar"
      severity="danger"
      size="small"
      type="submit"
      [disabled]="!formGroup.valid"
    />
  </span>
</form>
