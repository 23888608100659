import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShopOrderDetailComponent } from './shop-order-detail/shop-order-detail.component';
import { ShopOrdersComponent } from './shop-orders.component';

const routes: Routes = [
  {
    path: '',
    component: ShopOrdersComponent
  },
  {
    path: ':shop',
    component: ShopOrdersComponent
  },
  {
    path: 'order/:orderId',
    component: ShopOrderDetailComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShopOrdersRoutingModule {}
