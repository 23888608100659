import { Inject, Injectable } from '@angular/core';
import { ProductRepository, ProductReview, Where } from '@infrab4a/connect';
import { Papa, ParseResult } from 'ngx-papaparse';
import { Observable, from, of, throwError } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { MessageService } from 'primeng/api';
import { ProductReviewImportError } from '../../../models/batch/errors/product-review-import.error';
import {
  ReviewImportData,
  reviewsColumnImport
} from '../../../models/batch/types/review-import-data.type';
import { BatchService } from './batch.service';

@Injectable({
  providedIn: 'root'
})
export class ReviewsBatchService implements BatchService {
  itemsForUpload: ReviewImportData[];
  chunkSize = 100;
  completeCount = 0;
  errors = [];
  reviewsByProducts: { [key: string]: ProductReview[] } = {};

  constructor(
    private papa: Papa,
    @Inject('ProductRepository') private repository: ProductRepository,
    private messageService: MessageService
  ) {}

  async parse(input: File): Promise<ReviewImportData[]> {
    return new Promise<ReviewImportData[]>((resolve, reject) => {
      if (!input) resolve((this.itemsForUpload = []));

      this.papa.parse(input, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: (results: ParseResult<ReviewImportData[]>) => {
          try {
            if (results.errors.length > 0) throw results.errors.shift();

            const headers = Object.keys(results.data?.[0]) || [];

            Object.keys(reviewsColumnImport).forEach((columnName) => {
              if (
                !['createdAt', 'status'].includes(columnName) &&
                !headers.includes(columnName)
              )
                throw new Error(
                  `A coluna ${columnName} não foi encontrada no arquivo.`
                );
            });
            headers.forEach((columnName) => {
              if (reviewsColumnImport[columnName] === undefined)
                throw new Error(`A coluna ${columnName} é inválida.`);
            });
            this.itemsForUpload = results.data
              .filter((c) => Object.keys(c).some((k) => c[k]))
              .map((r) => {
                for (const key of Object.keys(r)) {
                  if (key !== 'createdAt' && key !== 'status' && !r[key])
                    throw new Error(`A coluna ${key} é obrigatória.`);
                  if (typeof r[key] === 'string') r[key] = r[key].trim();
                  else r[key] = Number(r[key]);
                }
                return r;
              });
            resolve(this.itemsForUpload);
          } catch (error) {
            reject(error);
          }
        }
      });
    });
  }

  async update(): Promise<Array<Partial<ProductReview>>> {
    if (this.itemsForUpload?.length <= 0) {
      return;
    }

    this.errors = [];
    this.reviewsByProducts = {};

    this.itemsForUpload.forEach((review) => {
      this.reviewsByProducts[review.sku] = [
        ...(this.reviewsByProducts[review.sku] || []),
        {
          author: review.author,
          email: review.email,
          location: review.location,
          review: review.review,
          title: review.title,
          rate: Number(review.rate),
          status:
            review.status === undefined
              ? null
              : !Number.isNaN(review.status) && Number(review.status) > 0,
          createdAt:
            review.createdAt && !Number.isNaN(Date.parse(review.createdAt))
              ? new Date(review.createdAt)
              : new Date(),
          shop: review.shop as any
        }
      ];
    });

    return await this.startImportation();
  }

  get importProgress(): number {
    const total = Object.values(this.reviewsByProducts)
      .map((reviews) => reviews.length)
      .reduce((totalReviews, count) => totalReviews + count, 0);

    return +((this.completeCount * 100) / total).toFixed(0);
  }

  private async startImportation(): Promise<Array<Partial<ProductReview>>> {
    const skus = Object.keys(this.reviewsByProducts);
    let updated: Array<
      ProductReview & { productId: string; productName: string }
    > = [];

    const products = (
      await this.repository.find({
        filters: { sku: { operator: Where.IN, value: skus } },
        limits: {
          limit: 9999
        }
      })
    ).data;

    const promises = [];
    for (const product of products) {
      const reviews = this.reviewsByProducts[product.sku];
      updated = updated.concat(
        reviews.map((r) => ({
          ...r,
          productId: product.id,
          productName: product.name
        }))
      );
      promises.push(this.saveProduct(product.id, reviews));
    }
    await Promise.all(promises);

    if (products.length < skus.length) {
      this.errors = (this.errors || []).concat({
        message: `SKUs não encontrados [${skus
          .toString()
          .replaceAll(',', ', ')}]`
      });
    }
    this.messageService.add({
      severity: 'success',
      detail: `Foram atualizadas ${updated.length} reviews`,
      summary: 'Sucesso'
    });

    return updated;
  }

  private async saveProduct(
    productId: string,
    reviews: Array<ProductReview>
  ): Promise<void> {
    try {
      await this.repository.update({
        id: productId,
        reviews: {
          action: 'merge',
          value: reviews
        }
      });
    } catch (error) {
      console.error(error);
      this.errors.push(error);
    }
  }

  private saveReview<T extends { [key: string]: ReviewImportData[] }>(
    review: T
  ): Observable<Observable<T>> {
    const [sku, reviews] = Object.entries(review).shift();
    const query = this.repository.find({
      filters: { sku: { operator: Where.EQUALS, value: sku } },
      limits: { limit: 1 }
    });

    return from(query).pipe(
      map((result) => result.data.shift()),
      concatMap((product) =>
        product
          ? of(of(product))
          : throwError(
              () => new Error(`Nenhum Produto encontrado com o sku ${sku}`)
            )
      ),
      map((productObservable) =>
        productObservable.pipe(
          concatMap((product) =>
            this.repository.update({
              id: product.id,
              reviews: { action: 'merge', value: reviews as any }
            })
          ),
          map(() => review)
        )
      ),
      catchError((error: any) =>
        of(
          throwError(
            () => new ProductReviewImportError(error.message, reviews, sku)
          )
        )
      )
    );
  }
}
