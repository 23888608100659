import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule, isDevMode } from '@angular/core';
import { initializeApp } from '@angular/fire/app';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Shops } from '@infrab4a/connect';
import { AngularConnectModule } from '@infrab4a/connect-angular';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { environment } from 'src/environments/environment';
import { ApiModule, Configuration } from './admin-api';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuditApiModule } from './audit-api';
import { LoaderModule } from './components/loader/loader.module';
import { SideBarModule } from './components/side-bar/sidebar.module';
import { UnauthorizedModule } from './pages/unauthorized/unauthorized.module';
import { HttpClientInterceptor } from './services/auth/http-client-interceptor';
import { TokenStorageService } from './services/auth/token-storage.service';

registerLocaleData(localePt);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    LoaderModule,
    CommonModule,
    SideBarModule,
    DialogModule,
    ButtonModule,
    HttpClientModule,
    AppRoutingModule,
    ToastModule,
    FileUploadModule,
    NgxPermissionsModule.forRoot(),
    AngularConnectModule.initializeApp(Shops.ALL, {
      hasura: {
        endpoint: environment.hasura.endpoint,
        credentials: { adminSecret: environment.hasura.adminSecret }
      },
      firebase: environment.connectFirebase,
      elasticSearch: environment.elasticSearch,
      backendUrl: environment.apiUrl,
      oldCategoryStructure: false
    }),
    ApiModule.forRoot(
      () =>
        new Configuration({
          accessToken: TokenStorageService.token,
          basePath: environment.apiUrl
        })
    ),
    AuditApiModule.forRoot(
      () =>
        new Configuration({
          accessToken: TokenStorageService.token,
          basePath: environment.auditApiUrl
        })
    ),
    UnauthorizedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    provideMessaging(() =>
      getMessaging(initializeApp(environment.firebase, 'admin'))
    )
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: () => {
        return new HttpClientInterceptor();
      },
      multi: true
    },
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
