<div class="person-credit-cards">
  <app-table
    [lazy]="false"
    [elements]="creditCards"
    *ngIf="creditCards"
    modelName="cartões"
    [cols]="cardCols"
    sortField="dateCreated"
    [sortOrder]="1"
    [actionButtons]="actionButtons"
    (actionButtonClick)="confirmDeleteCard($event.item, $event.$event)"
    [defaultRows]="10"
    [showTitle]="false"
    [exportPdf]="false"
    [exportExcel]="false"
    [addButton]="false"
    [changeColumns]="true"
  ></app-table>
</div>
<p-confirmPopup styleClass="danger"> </p-confirmPopup>
