import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { AddressInfoModule } from 'src/app/components/address-info/address-info.module';
import { BlacklistFormModule } from 'src/app/components/blacklist-form/blacklist-form.module';
import { CreditCardViewModule } from 'src/app/components/credit-card-view/credit-card-view.module';
import { DisableRecurrenceModalModule } from 'src/app/components/disable-recurrence-modal/disable-recurrence-modal.module';
import { InfluencerFormModule } from 'src/app/components/influencer-form/influencer-form.module';
import { PaymentDetailsModalModule } from 'src/app/components/payment-details-modal/payment-details-modal.module';
import { PersonActionModalModule } from 'src/app/components/person-action-modal/person-action-modal.module';
import { PersonBeautyprofileModule } from 'src/app/components/person-beautyprofile/person-beautyprofile.module';
import { PersonCardModule } from 'src/app/components/person-card/person-card.module';
import { PersonCreditCardsModule } from 'src/app/components/person-credit-cards/person-credit-cards.module';
import { PersonFormModule } from 'src/app/components/person-form/person-form.module';
import { PersonGlampointsModule } from 'src/app/components/person-glampoints/person-glampoints.module';
import { PersonInstagramHistoryModule } from 'src/app/components/person-instagram-history/person-instagram-history.module';
import { PersonInvitesModule } from 'src/app/components/person-invites/person-invites.module';
import { PersonMovementsModule } from 'src/app/components/person-movements/person-movements.module';
import { PersonSocialMediasModule } from 'src/app/components/person-social-medias/person-social-medias.module';
import { RewardModalModule } from 'src/app/components/reward-modal/reward-modal.module';
import { SubscriberCancellationModalModule } from 'src/app/components/subscriber-cancellation-modal/subscriber-cancellation-modal.module';
import { SubscriberCardModule } from 'src/app/components/subscriber-card/subscriber-card.module';
import { SubscriberCompositionsModule } from 'src/app/components/subscriber-compositions/subscriber-compositions.module';
import { AppTableModule } from 'src/app/components/table/app-table.module';
import { BlacklistComponent } from './blacklist/blacklist.component';
import { PaymentsComponent } from './payments/payments.component';
import { PersonDetailsComponent } from './person-details/person-details.component';
import { PersonComponent } from './person/person.component';
import { SubscriberCancellationsComponent } from './subscriber-cancellations/subscriber-cancellations.component';
import { SubscriberDetailsComponent } from './subscriber-details/subscriber-details.component';
import { SubscriberRefundDetailsModalComponent } from './subscriber-refunds/subscriber-refund-details-modal/subscriber-refund-details-modal.component';
import { SubscriberRefundsComponent } from './subscriber-refunds/subscriber-refunds.component';
import { SubscribersComponent } from './subscribers/subscribers.component';
import { UsersRoutingModule } from './users-routing.module';

@NgModule({
  declarations: [
    PersonComponent,
    SubscribersComponent,
    SubscriberCancellationsComponent,
    PersonDetailsComponent,
    SubscriberDetailsComponent,
    PaymentsComponent,
    BlacklistComponent,
    SubscriberRefundsComponent,
    SubscriberRefundDetailsModalComponent
  ],
  imports: [
    CommonModule,
    AppTableModule,
    InputTextModule,
    ButtonModule,
    TooltipModule,
    DropdownModule,
    ReactiveFormsModule,
    UsersRoutingModule,
    OverlayPanelModule,
    PersonCardModule,
    PersonFormModule,
    TabViewModule,
    SubscriberCardModule,
    PersonInvitesModule,
    PersonBeautyprofileModule,
    PersonGlampointsModule,
    RewardModalModule,
    PersonInstagramHistoryModule,
    SubscriberCompositionsModule,
    ToastModule,
    SubscriberCancellationModalModule,
    DisableRecurrenceModalModule,
    PaymentDetailsModalModule,
    InfluencerFormModule,
    ConfirmPopupModule,
    InputNumberModule,
    InputMaskModule,
    PersonMovementsModule,
    PersonCreditCardsModule,
    BlacklistFormModule,
    PersonSocialMediasModule,
    MultiSelectModule,
    DialogModule,
    InputTextareaModule,
    FormsModule,
    PersonActionModalModule,
    AddressInfoModule,
    ConfirmDialogModule,
    NgxPermissionsModule.forChild(),
    CreditCardViewModule
  ]
})
export class UsersModule {}
