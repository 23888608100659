import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Role } from 'src/app/models';
import { AuthGuard } from 'src/app/services/auth/auth-guard';
import { FailExportComponent } from './fail-export/fail-export.component';
import { IntegrationTokensComponent } from './integration-tokens/integration-tokens.component';
import { IntegrationComponent } from './integration.component';
import { UpdateShippingCompanyActivationComponent } from './update-shipping-company-activation/update-shipping-company-activation.component';
import { UpdateShippingCompanyComponent } from './update-shipping-company/update-shipping-company.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  },
  {
    path: 'dashboard',
    component: IntegrationComponent,
    canActivate: [
      () =>
        inject(AuthGuard).canActivatePermissions([
          Role.Full_Administrator,
          Role.ItOps,
          Role.TI
        ])
    ]
  },
  {
    path: 'fail-export',
    component: FailExportComponent,
    canActivate: [
      () =>
        inject(AuthGuard).canActivatePermissions([
          Role.Full_Administrator,
          Role.ItOps,
          Role.TI
        ])
    ]
  },
  {
    path: 'update-shipping-company',
    component: UpdateShippingCompanyComponent
  },
  {
    path: 'update-shipping-company-activation',
    component: UpdateShippingCompanyActivationComponent
  },
  {
    path: 'tokens',
    component: IntegrationTokensComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IntegrationRoutingModule {}
