<div class="page-title">Detalhes da assinatura</div>
<div class="details" *ngIf="person && subscriber && subscriber.subscriptionId">
  <div class="details__left" *ngIf="!isMobile">
    <app-person-card
      [person]="person"
      [isMobile]="isMobile"
      [subscriptionId]="subscriber.subscriptionId"
      [influencer]="influencer"
    />
  </div>
  <div class="details__right">
    <div class="card subscriber-info">
      <span class="info">
        <strong
          >Assinatura:
          <span
            [class.info--box]="subscriber.subscriptionId === 1"
            [class.info--bag]="subscriber.subscriptionId === 5"
            [class.info--pass]="subscriber.subscriptionId === 6"
            [class.info--combo]="subscriber.subscriptionId === 7"
          >
            {{ subscriber.subscriptionName | titlecase }}
          </span>
        </strong>
      </span>
      <span class="info">
        <strong>GlamID</strong>:
        <a
          class="data-link"
          type="button"
          (click)="copyToClipboard(subscriber.boxId || '')"
        >
          {{ subscriber.boxId }}
          <i class="pi pi-copy"></i>
        </a>
      </span>
      <span class="info">
        <strong>Plano</strong>:
        {{ subscriber.subscriptionTypeName }}
      </span>
      <span class="info">
        <strong>Status</strong>:
        <span
          class="status change"
          [class.status--active]="subscriber.subscriberStatus === 0"
          [class.status--warning]="subscriber.subscriberStatus === 10"
          (click)="
            subscriber.subscriberStatus &&
              subscriber.subscriberStatus !== 10 &&
              statusList &&
              showDropdown()
          "
          *ngIf="!changeStatus"
        >
          {{ subscriber.status }}
        </span>
        <span *ngIf="changeStatus && statusList">
          <p-dropdown
            [options]="statusList"
            optionLabel="status"
            optionValue="subscriberStatusId"
            [formControl]="subscriberStatus"
            (onChange)="changeSubscriberStatus()"
            (onBlur)="changeStatus = false"
            appendTo="body"
            (onHide)="changeStatus = false"
            [autoDisplayFirst]="false"
          >
          </p-dropdown>
        </span>
      </span>
      <span class="info">
        <strong>Desde</strong>
        {{ subscriber.dateCreated | date : 'dd/MM/yyyy' }}
      </span>
      <span class="info">
        <strong>Frete</strong>
        {{ subscriber.shippingPrice | currency : 'BRL' }}
      </span>
      <span class="info">
        <strong>Endereço de entrega</strong>
        <br />
        {{ subscriber.shippingStreet }}
        {{ subscriber.shippingStreetNumber
        }}<span *ngIf="subscriber.shippingStreetExtensions?.length"
          >, {{ subscriber.shippingStreetExtensions }}</span
        >
        - {{ subscriber.shippingNeighborhood }}
        {{ subscriber.shippingCity }} -{{ subscriber.shippingState }}, Brasil
        CEP {{ subscriber.shippingPostalCode }}
      </span>
      <div
        class="payment-summary"
        *ngIf="paymentHistory && lastPayment"
        [class.payment-summary--two-columns]="
          !creditCard?.nextAttemptDate && !creditCard?.nextPaymentDate
        "
      >
        <span class="payment-summary__card" *ngIf="creditCard">
          <strong>Cartão atual</strong>
          <app-credit-card-view [creditCard]="creditCard" />
        </span>
        <div
          class="payment-summary__last"
          *ngIf="paymentHistory && lastPayment"
        >
          <h4>Último pagamento</h4>
          <p>
            <i class="pi pi-clock"></i>
            {{ lastPayment.dateCreated | date : 'dd/MM/yyyy HH:mm' }}
          </p>
          <p>
            <i class="pi pi-dollar"></i>
            {{ lastPayment.amount | currency : 'BRL' }} (em
            {{ lastPayment.installments }}x)
          </p>
          <p
            class="status"
            [class.status--active]="lastPayment.transactionStatusId === 6"
          >
            {{ lastPayment.transactionStatusName }}
          </p>
        </div>
        <div
          class="payment-summary__next"
          *ngIf="creditCard?.nextAttemptDate || creditCard?.nextPaymentDate"
        >
          <h4>Próxima cobrança&nbsp;</h4>
          <p *ngIf="creditCard?.nextAttemptDate">
            <i class="pi pi-clock"></i>
            {{ creditCard?.nextAttemptDate | date : 'dd/MM/yyyy HH:mm' }}
            <span
              class="status"
              *ngIf="subscriber.subscriberStatus === 0"
              [class.status--active]="recurrenceActive"
            >
              {{ creditCard?.creditCardRecurrenceStatusName || 'Inativo' }}
            </span>
          </p>
          <p
            *ngIf="!creditCard?.nextAttemptDate && creditCard?.nextPaymentDate"
          >
            <i class="pi pi-clock"></i>
            {{ creditCard?.nextPaymentDate | date : 'dd/MM/yyyy HH:mm' }}
            <span class="status" [class.status--active]="recurrenceActive">
              {{ creditCard?.creditCardRecurrenceStatusName || 'Inativo' }}
            </span>
          </p>
          <p>
            <i class="pi pi-dollar"></i>
            {{ creditCard?.amount | currency : 'BRL' }} (em
            {{ creditCard?.installments }}x)
          </p>
          <p>
            Tentativa: {{ creditCard?.attempt }}/{{ creditCard?.maxAttempts }}
          </p>
          <p>
            Última atualização:
            {{ creditCard?.dateUpdated | date : 'dd/MM/yyyy HH:mm' }}
          </p>
        </div>
      </div>
      <div *ngIf="scheduledCancellation">
        <p class="cancellation-schedule">
          * A assinatura será cancelada a partir de
          {{
            referenceAsDate(scheduledCancellation.cancellationReference)
              | date : 'dd/MM/yyyy'
          }}
        </p>
      </div>
      <div class="buttons" *ngxPermissionsOnly="acceptedPermissions">
        <p-button
          label="Estornar"
          (onClick)="chargeBack()"
          *ngIf="billingsToChargeback?.length"
        />
        <p-button
          label="Cancelar assinatura"
          (onClick)="inactivate()"
          *ngIf="
            (subscriber.subscriberStatus === 0 ||
              subscriber.subscriberStatus === 10) &&
            !scheduledCancellation
          "
        />
        <p-button
          label="Desabilitar recorrência"
          (onClick)="disableRecurrence()"
          *ngIf="subscriber.subscriberStatus === 0 && recurrenceActive"
        />
      </div>
    </div>
    <div class="card">
      <p-tabView
        [activeIndex]="selectedTab"
        (activeIndexChange)="tabChanged($event)"
        *ngIf="pageReady"
      >
        <p-tabPanel [header]="'Edições (' + (oldEditions?.length || 0) + ')'">
          <app-subscriber-compositions
            [lastPayment]="lastSuccessfulPayment"
            [compositions]="compositions"
            [person]="person"
            [isDesktop]="isDesktop"
            [subscriber]="subscriber"
            (editionChanged)="editionChanged()"
          />
        </p-tabPanel>
        <p-tabPanel
          [header]="'Cobranças (' + (paymentHistory?.length || 0) + ')'"
        >
          <div class="payments" *ngIf="paymentHistory">
            <app-table
              [lazy]="false"
              [elements]="paymentHistory"
              [cols]="paymentCols"
              dataKey="subscriberPaymentHistoryId"
              expandField="billings"
              [expansionCols]="billingCols"
              sortField="dateCreated"
              [sortOrder]="1"
              emptyMessage="{{ person.name }} não realizou pagamentos"
              [exportPdf]="false"
              [exportExcel]="false"
              [showTitle]="false"
              [addButton]="false"
              [changeColumns]="true"
            />
          </div>
        </p-tabPanel>
        <p-tabPanel
          [header]="'Vouchers (' + voucherBillings.length + ')'"
          *ngIf="voucherBillings && voucherBillings?.length"
        >
          <app-table
            [lazy]="false"
            [elements]="voucherBillings"
            [cols]="voucherCols"
            [exportPdf]="false"
            [exportExcel]="false"
            [showTitle]="false"
            [addButton]="false"
            [changeColumns]="true"
            sortField="dateCreated"
            [sortOrder]="1"
          />
        </p-tabPanel>
        <p-tabPanel [header]="'Renovações (' + (renewals?.length || 0) + ')'">
          <app-table
            [cols]="renewalsCols"
            [elements]="renewals"
            sortField="dateCreated"
            [sortOrder]="1"
            [lazy]="false"
            emptyMessage="{{ person.name }} não realizou agendamentos"
            [exportPdf]="false"
            [exportExcel]="false"
            [showTitle]="false"
            [addButton]="false"
            [changeColumns]="true"
          />
        </p-tabPanel>
        <p-tabPanel
          [header]="'Alterações (' + (addressChanges?.length || 0) + ')'"
        >
          <app-table
            [cols]="addressChangeCols"
            [elements]="addressChanges"
            sortField="dateChanged"
            [sortOrder]="1"
            modelName="alteraçoes"
            [exportPdf]="false"
            [exportExcel]="false"
            [showTitle]="false"
            [addButton]="false"
            [changeColumns]="true"
            [lazy]="false"
            emptyMessage="{{ person.name }} não realizou alterações de endereço"
          >
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th
                  *ngFor="let col of columns"
                  pReorderableColumn
                  pResizableColumn
                  [pSortableColumn]="col.field"
                >
                  {{ col.header }}
                  <p-sortIcon [field]="col.field" />
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-change let-columns="columns">
              <tr>
                <td
                  *ngFor="let col of columns"
                  [class.text-center]="isMobile && !col.showMobile"
                  [class.text-center]="col.type === 'link'"
                >
                  <span class="p-column-title" *ngIf="col.showMobile">{{
                    col.header
                  }}</span>
                  <ng-container *ngIf="col.type === 'text'">
                    {{ change[col.field] }}
                  </ng-container>
                  <ng-container *ngIf="col.type === 'date'">
                    {{ change[col.field] | date : 'dd/MM/yyyy HH:mm:ss' }}
                  </ng-container>
                  <ng-container *ngIf="col.type === 'link'">
                    <a
                      type="button"
                      [class.data-link]="change[col.field]"
                      (click)="
                        change.paymentId && showPayment(change.paymentId)
                      "
                    >
                      <ng-container *ngIf="change[col.field]">
                        <i
                          class="pi pi-credit-card"
                          style="font-size: 22px"
                        ></i>
                      </ng-container>
                      <ng-container *ngIf="!change[col.field]">
                        <i class="pi pi-times" style="color: red"></i>
                      </ng-container>
                    </a>
                  </ng-container>
                </td>
              </tr>
            </ng-template>
          </app-table>
        </p-tabPanel>
        <p-tabPanel [header]="'Estornos (' + (refunds?.length || 0) + ')'">
          <app-table
            [cols]="refundCols"
            [elements]="refunds"
            [actionButtons]="refundButtons"
            [lazy]="false"
            sortField="dateCreated"
            [sortOrder]="1"
            [exportPdf]="false"
            [exportExcel]="false"
            [showTitle]="false"
            [addButton]="false"
            [changeColumns]="true"
            (actionButtonClick)="callAction($event)"
            modelName="estornos"
            emptyMessage="{{ person.name }} não realizou estornos"
            *ngIf="refunds"
          />
        </p-tabPanel>
        <p-tabPanel [header]="'Cupons (' + (coupons?.length || 0) + ')'">
          <app-table
            [cols]="couponCols"
            [elements]="coupons"
            [lazy]="false"
            sortField="dateCreated"
            [sortOrder]="1"
            modelName="cupons utilizados"
            emptyMessage="{{ person.name }} não utilizou cupons"
            [exportPdf]="false"
            [exportExcel]="false"
            [showTitle]="false"
            [addButton]="false"
            [changeColumns]="true"
          />
        </p-tabPanel>
        <p-tabPanel
          [header]="'Cancelamentos (' + (cancellations?.length || 0) + ')'"
        >
          <app-table
            [cols]="cancellationCols"
            [elements]="cancellations"
            sortField="dateCancelled"
            [sortOrder]="1"
            [lazy]="false"
            [exportPdf]="false"
            [exportExcel]="false"
            [showTitle]="false"
            [addButton]="false"
            [changeColumns]="true"
            emptyMessage="{{ person.name }} não realizou cancelamentos"
            modelName="cancelamentos"
            *ngIf="cancellations"
          />
        </p-tabPanel>
        <p-tabPanel header="Movimentações">
          <app-person-movements
            *ngIf="person.personId"
            [personId]="person.personId"
          />
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>
<p-toast />
<p-confirmPopup *ngIf="!cancellationOpen" />
