import { Clipboard } from '@angular/cdk/clipboard';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Order } from '@infrab4a/connect';
import { NgxPermissionsService } from 'ngx-permissions';
import { ConfirmationService, MessageService, PrimeIcons } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TabView } from 'primeng/tabview';
import { lastValueFrom, map } from 'rxjs';
import {
  BadgeActionType,
  BadgeControllerService,
  BeautyProfileControllerService,
  CreditCardSummary,
  FinancialControllerService,
  Group,
  InfluencerControllerService,
  InfluencerCouponDetailView,
  InfluencerDetail,
  InfluencerStatus,
  InstagramUser,
  PageableFilter,
  PersonActionDetailed,
  PersonControllerService,
  PersonDetail,
  PersonLogTransaction,
  PersonRelationInfo,
  PersonReward,
  RewardControllerService,
  RewardType,
  SocialMediaUserDetail,
  SocialMediaUserLogDetail,
  SubscriberControllerService,
  SubscriberInfo
} from 'src/app/admin-api';
import { PersonActionModalComponent } from 'src/app/components/person-action-modal/person-action-modal.component';
import {
  DropdownFilter,
  TableActionButton,
  TableColumn
} from 'src/app/components/table';
import { ShopOrderService } from 'src/app/connect-api/api/shop/shop-order.service';
import { ShopMap } from 'src/app/connect-api/enums/ShopMap';
import {
  PaymentMethod,
  getAllPaymentMethod
} from 'src/app/connect-api/models/PaymentMethod';
import {
  PaymentStatus,
  getAllPaymentStatus
} from 'src/app/connect-api/models/PaymentStatus';
import { Role, roleAsObject } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-person-details',
  templateUrl: './person-details.component.html',
  styleUrls: ['./person-details.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class PersonDetailsComponent implements OnInit {
  @ViewChild(TabView)
  tabview: TabView | undefined;

  person: PersonDetail | undefined;
  subscribers: Array<SubscriberInfo> | undefined;
  personForm: FormGroup | undefined;
  selectedTab = 0;
  invites: Array<PersonRelationInfo> | undefined;
  beautyProfile: Array<Group> | undefined;
  rewards: Array<PersonReward> | undefined;
  rewardTypes: Array<RewardType> | undefined;
  instagramHistory: Array<InstagramUser> | undefined;
  isMobile?: boolean;
  influencer: InfluencerDetail | undefined;
  influencerStatusList: Array<InfluencerStatus> | undefined;
  coupons: Array<InfluencerCouponDetailView> | undefined;
  creditCards: Array<CreditCardSummary & { flag: string }> | undefined;
  socialMedias: Array<SocialMediaUserDetail> | undefined;
  socialMediaHistory: Array<SocialMediaUserLogDetail> | undefined;
  personActions: Array<PersonActionDetailed> | undefined;
  badgeActionTypes: Array<BadgeActionType> | undefined;
  currentPersonAction: PersonActionDetailed | undefined;
  permission = false;

  buttons: Array<TableActionButton> = [
    new TableActionButton(
      '',
      'showDetail',
      PrimeIcons.FILE,
      (log: PersonLogTransaction) => log.responseBody !== undefined,
      undefined,
      'Exibir Detalhes',
      'bottom',
      true,
      true,
      'white',
      'small'
    )
  ];
  filters: Array<PageableFilter>;
  logCols: Array<TableColumn> = [
    new TableColumn('logId', 'id', false, 'number'),
    new TableColumn('Método', 'method', false, 'text'),
    new TableColumn('EndPoint', 'url', false, 'text'),
    new TableColumn('Status', 'status', false, 'number'),
    new TableColumn('Data Início', 'started', false, 'date'),
    new TableColumn('Data Final', 'finished', false, 'date'),
    new TableColumn('Detalhes', '', false, 'button')
  ];
  orderCols: Array<TableColumn> = [
    new TableColumn(
      'ID',
      'orderNumber',
      true,
      'text',
      '/shop-orders/order/',
      'id',
      true,
      'equals'
    ),
    new TableColumn.Builder()
      .setHeader('Forma Pagamento')
      .setField('payment.paymentMethod')
      .setCondition('in')
      .setDisplayFunction(
        (order: Order) => PaymentMethod[order.payment.paymentMethod]
      )
      .build(),
    new TableColumn.Builder().setHeader('TID').setField('payment.tid').build(),
    new TableColumn.Builder()
      .setHeader('Status Pagamento')
      .setField('payment.status')
      .setDisplayFunction((order: Order) => PaymentStatus[order.payment.status])
      .setCondition('in')
      .build(),
    new TableColumn.Builder()
      .setHeader('Produtos')
      .setField('lineItems.length')
      .setType('formattedInteger')
      .setCondition('gte')
      .setDisplayFunction((order: Order) => order.lineItems?.length)
      .build(),
    new TableColumn.Builder()
      .setHeader('Loja')
      .setField('shop')
      .setCondition('in')
      .setDisplayFunction((order: Order) => ShopMap[order.shop])
      .build(),
    new TableColumn.Builder()
      .setHeader('Transportadora')
      .setField('shipping.ShippingCompanyName')
      .setCondition('contains')
      .setDisplayFunction((order: Order) => order.shipping?.ShippingCompanyName)
      .build(),
    new TableColumn.Builder()
      .setHeader('Status')
      .setField('status')
      .setCondition('in')
      .build(),
    new TableColumn.Builder()
      .setHeader('Cupom')
      .setField('coupon.nickname')
      .setDisplayFunction((order: Order) => order.coupon?.nickname)
      .build(),
    new TableColumn.Builder()
      .setHeader('Data')
      .setField('createdAt')
      .setType('date')
      .setCondition('between')
      .build(),
    new TableColumn.Builder()
      .setHeader('Total')
      .setField('totalPrice')
      .setType('currency')
      .setCondition('gte')
      .build(),
    new TableColumn.Builder()
      .setHeader('Prazo')
      .setField('shipping.DaysToDelivery')
      .setDisplayFunction((order: Partial<Order>) =>
        order.shipping?.DaysToDelivery
          ? `${order.shipping.DaysToDelivery} dia(s)`
          : (order.shipping as any)?.description || 'Indeterminado'
      )
      .setCondition('gte')
      .build(),
    new TableColumn.Builder()
      .setHeader('Data entrega')
      .setField('shipping.DaysToDelivery')
      .setDisplayFunction((order: Partial<Order>) =>
        this.shopOrderService.deliveryDate(order)
      )
      .setCondition('gte')
      .build()
  ];
  orders: Array<Partial<Order>> = [];
  actionsCols: Array<TableColumn> = [
    new TableColumn('Descrição', 'description', true, 'text'),
    new TableColumn('Referência', 'referenceId', false, 'number'),
    new TableColumn('Confirmado em', 'dateConfirmed', false, 'date'),
    new TableColumn('Manual?', 'manual', true, 'boolean'),
    new TableColumn('Usuário', 'username', true, 'text'),
    new TableColumn('Ação', '', false, 'button')
  ];
  actionButtons: Array<TableActionButton> = [
    new TableActionButton(
      '',
      'Excluir',
      PrimeIcons.TRASH,
      () => this.permission,
      '',
      'Excluir',
      'bottom',
      true,
      true,
      'danger',
      'small'
    )
  ];
  logDetail: string | undefined;
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.checkScreenSize();
  }
  dropdownFilters: { [key: string]: Array<DropdownFilter> } = {
    manual: [
      { label: 'Não', value: false },
      { label: 'Sim', value: true },
      { label: 'Todos', value: null }
    ],
    status: [
      { label: 'Aguardando pagamento', value: 'Aguardando pagamento' },
      { label: 'Preparando pedido', value: 'Preparando pedido' },
      { label: 'Enviado', value: 'Enviado' },
      { label: 'Entregue', value: 'Entregue' },
      { label: 'Cancelado', value: 'Cancelado' }
    ],
    shop: [
      { label: 'Glam', value: 'Glamshop' },
      { label: 'Glampoints', value: 'Glampoints' },
      { label: `Men's Market`, value: 'mensmarket' }
    ],
    'payment.paymentMethod': getAllPaymentMethod(),
    'payment.status': getAllPaymentStatus()
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    public personService: PersonControllerService,
    private subscriberService: SubscriberControllerService,
    private title: Title,
    private beautyprofileService: BeautyProfileControllerService,
    private rewardService: RewardControllerService,
    private router: Router,
    private influencerService: InfluencerControllerService,
    private financialService: FinancialControllerService,
    private clipboard: Clipboard,
    private messageService: MessageService,
    private badgeService: BadgeControllerService,
    private dialog: DialogService,
    private confirmationService: ConfirmationService,
    private permissionsService: NgxPermissionsService,
    private shopOrderService: ShopOrderService
  ) {
    this.checkScreenSize();
  }

  copyToClipboard(text: string): void {
    this.clipboard.copy(text);
    this.messageService.add({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Copiado para área de trasnferência'
    });
  }

  checkScreenSize(): void {
    this.isMobile = window.innerWidth < 768;
  }

  async ngOnInit(): Promise<void> {
    this.permission = await this.permissionsService.hasPermission([
      roleAsObject(Role.Full_Administrator).enumValue
    ]);
    this.activatedRoute.params.subscribe(async (params) => {
      if (params['id']) {
        LoaderService.showLoader();
        this.person = {
          personId: Number(params['id'])
        };
        this.filters = [
          {
            condition: 'equals',
            field: 'personId',
            fieldType: 'number',
            value: this.person.personId.toString()
          }
        ];
        await Promise.all([
          this.findPerson(),
          this.findSubscribers(),
          this.findInvites(),
          this.findBeautyProfile(),
          this.findRewards(),
          this.findRewardTypes(),
          this.findInstagramHistory(),
          this.findInfluencerInfo(),
          this.findInfluencerStatusList(),
          this.findPersonCards(),
          this.findSocialMediasHistory(),
          this.findAllActions(),
          this.getAllActionTypes()
        ]);
        await this.findPersonOrders();
        this.activatedRoute.queryParams.subscribe((queryParams) => {
          if (queryParams['tab']) {
            this.selectedTab = Number(queryParams['tab']) || 0;
          } else {
            this.selectedTab = 0;
          }
        });
        LoaderService.showLoader(false);
      } else {
        AppDialogService.showErrorDialog(
          { message: 'Usuário não encontrado' },
          true
        );
      }
    });
  }

  async findPersonOrders(): Promise<void> {
    try {
      const results = await Promise.all([
        this.shopOrderService.getOrdersByEmail(this.person?.username),
        this.shopOrderService.getOrdersByPersonId(this.person?.personId)
      ]);
      const orders = results.reduce((r, orders) => orders.concat(r || []), []);
      this.orders = FormUtil.onlyUniques(orders, 'id');
    } catch (error: any) {
      AppDialogService.showErrorDialog(error, false, 'Erro ao buscar pedidos');
    }
  }

  async findPerson(): Promise<void> {
    try {
      this.person = await lastValueFrom(
        this.personService
          .findPersonInfoById(this.person?.personId as number)
          .pipe(map((data) => data.result))
      );
      this.title.setTitle(
        `${this.person?.name} ${this.person?.lastName || ''}`
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error, true, 'Usuário não encontrado');
    }
  }

  async findSubscribers(): Promise<void> {
    delete this.subscribers;
    try {
      this.subscribers = await lastValueFrom(
        this.subscriberService
          .findPersonSubscribers(this.person?.personId as number)
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      this.subscribers = [];
      AppDialogService.showErrorDialog(
        error,
        false,
        'Assinaturas não encontradas'
      );
    }
  }

  async findInvites(): Promise<void> {
    delete this.invites;
    try {
      this.invites = await lastValueFrom(
        this.personService
          .findPersonInvites(this.person?.personId as number)
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      this.invites = [];
      AppDialogService.showErrorDialog(
        error,
        false,
        'Convites não encontradas'
      );
    }
  }

  async findBeautyProfile(): Promise<void> {
    delete this.beautyProfile;
    try {
      this.beautyProfile = await lastValueFrom(
        this.beautyprofileService
          .findPersonBeautyProfile(this.person?.personId as number)
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      this.beautyProfile = [];
      AppDialogService.showErrorDialog(
        error,
        false,
        'Perfil de beleza não encontradas'
      );
    }
  }

  async findRewards(): Promise<void> {
    delete this.rewards;
    try {
      this.rewards = await lastValueFrom(
        this.rewardService
          .findPersonRewards(this.person?.personId as number)
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      this.rewards = [];
      AppDialogService.showErrorDialog(
        error,
        false,
        'Histórico de glampoints não encontradas'
      );
    }
  }

  async findRewardTypes(): Promise<void> {
    delete this.rewardTypes;
    try {
      this.rewardTypes = await lastValueFrom(
        this.rewardService.findRewardTypes().pipe(map((data) => data.result))
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(
        error,
        false,
        'Erro ao buscar rewardtypes'
      );
    }
  }

  async findInstagramHistory(): Promise<void> {
    delete this.instagramHistory;
    try {
      this.instagramHistory = await lastValueFrom(
        this.personService
          .findInstagramHistory(this.person?.personId as number)
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      this.instagramHistory = [];
      AppDialogService.showErrorDialog(
        error,
        false,
        'Erro ao buscar histórico do Instagram'
      );
    }
  }

  async findInfluencerInfo(): Promise<void> {
    try {
      this.influencer = await lastValueFrom(
        this.influencerService
          .findInfluencerDetails(this.person?.personId as number)
          .pipe(map((data) => data.result))
      );
      await this.findAllCoupons();
    } catch (error: any) {
      delete this.influencer;
    }
  }

  async findInfluencerStatusList(): Promise<void> {
    try {
      this.influencerStatusList = await lastValueFrom(
        this.influencerService
          .findInfluencerStatusList()
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
      delete this.influencerStatusList;
    }
  }

  async findPersonCards(): Promise<void> {
    delete this.creditCards;
    try {
      this.creditCards = await lastValueFrom(
        this.financialService
          .findCardsByPersonId(this.person?.personId as number)
          .pipe(
            map((data) =>
              data.result?.map((c) => ({ ...c, flag: this.cardFlag(c) }))
            )
          )
      );
    } catch (error: any) {
      delete this.creditCards;
    }
  }

  async findSocialMediasHistory(): Promise<void> {
    delete this.socialMediaHistory;
    delete this.socialMedias;
    try {
      const [history, current] = await Promise.all([
        lastValueFrom(
          this.personService
            .findSocialMediaLogsByPersonId(this.person?.personId as number)
            .pipe(map((data) => data.result))
        ),
        lastValueFrom(
          this.personService
            .findSocialMediasByPersonId(this.person?.personId as number)
            .pipe(map((data) => data.result))
        )
      ]);
      this.socialMediaHistory = history;
      this.socialMedias = current;
    } catch (error: any) {
      this.socialMediaHistory = [];
      this.socialMedias = [];
      AppDialogService.showErrorDialog(
        error,
        false,
        'Erro ao buscar redes sociais'
      );
    }
  }

  cardFlag(card: CreditCardSummary): string {
    if (card) {
      if (!card.creditCardFlagName || card.creditCardFlagName === '') {
        card.creditCardFlagName = 'credit-card';
      }
      return (
        'assets/icons/card-flags/' +
        card.creditCardFlagName.toLowerCase().replace(' ', '') +
        '.svg'
      );
    }
    return 'assets/icons/card-flags/credit-card.svg';
  }

  async refresh(
    string: 'findInstagramHistory' | 'findPersonCards'
  ): Promise<void> {
    LoaderService.showLoader();
    await this[string]();
    LoaderService.showLoader(false);
  }

  onCouponChanges(couponName: any): void {
    if (this.person) this.person.couponName = couponName;
  }

  tabChanged($event: number): void {
    this.router.navigate([`users/person/${this.person?.personId}`], {
      queryParams: { tab: $event },
      queryParamsHandling: 'merge'
    });
  }

  async findAllCoupons(change = false): Promise<void> {
    if (change) {
      delete this.coupons;
      LoaderService.showLoader();
    }
    this.coupons = await lastValueFrom(
      this.influencerService
        .findInfluencerCoupons(this.influencer?.personId as number)
        .pipe(map((data) => data.result))
    );
    if (change) LoaderService.showLoader(false);
  }

  showResponseBody($event: {
    item: PersonLogTransaction;
    $event: Event;
    action: string;
  }): void {
    this.logDetail = $event.item.responseBody;
  }

  async findAllActions(actionsLength: number = undefined): Promise<void> {
    try {
      this.personActions = await lastValueFrom(
        this.personService
          .findPersonActions(this.person?.personId as number)
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      delete this.personActions;
    }
    if (
      actionsLength !== undefined &&
      actionsLength === this.personActions.length
    ) {
      AppDialogService.showErrorDialog({}, false, 'Erro ao criar nova ação');
    } else if (actionsLength !== undefined) {
      this.messageService.add({
        detail: 'Ação registrada com sucesso',
        summary: 'Sucesso',
        severity: 'success'
      });
    }
  }

  async getAllActionTypes(): Promise<void> {
    this.badgeActionTypes = await lastValueFrom(
      this.badgeService.findActionTypeList().pipe(map((data) => data.result))
    );
    this.dropdownFilters['description'] = this.badgeActionTypes
      .map((ac) => ({
        label: ac.description,
        value: ac.description
      }))
      .concat([
        {
          label: 'Todas',
          value: null
        }
      ]);
  }

  async buttonClick(event: {
    item: PersonActionDetailed;
    $event: Event;
    action: string;
  }) {
    this.currentPersonAction = event.item;
    this.confirmationService.confirm({
      header: 'Exclusão',
      message: 'Tem certeza que deseja excluir?',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: async () => {
        await this.deletePersonAction(this.currentPersonAction);
      },
      target: event.$event.target
    });
  }

  async deletePersonAction(personAction: PersonActionDetailed) {
    LoaderService.showLoader();
    try {
      const result = await lastValueFrom(
        this.personService
          .deletePersonAction(personAction.personActionId)
          .pipe(map((data) => data.result))
      );
      this.messageService.add({
        severity: 'success',
        detail: result,
        summary: 'Sucesso'
      });
      delete this.personActions;
      await this.findAllActions();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  openActionModal(): void {
    this.dialog
      .open(PersonActionModalComponent, {
        closable: true,
        closeOnEscape: true,
        header: `Criar ação para ${this.person?.name} ${this.person?.lastName}`,
        data: {
          person: this.person,
          actionTypes: this.badgeActionTypes
        },
        modal: true,
        width: this.isMobile ? '100%' : '400px'
      })
      .onClose.subscribe((data: string) => {
        if (data) {
          setTimeout(async () => {
            await this.findAllActions(this.personActions.length);
            LoaderService.showLoader(false);
          }, 2000);
        }
      });
  }

  closeModal(): void {
    delete this.logDetail;
  }
}
