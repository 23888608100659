<form
  class="form"
  [formGroup]="form"
  class="form-container"
  (ngSubmit)="save()"
  *ngIf="form"
>
  <h3 *ngIf="editionId === 'new'">Nova Edição</h3>
  <h3 *ngIf="editionId !== 'new'">Aterar Edição</h3>
  <p-panel header="Edição">
    <div class="form-field">
      <label for="editionTheme">Tema (obrigatório)</label>
      <input
        id="editionTheme"
        type="text"
        pInputText
        formControlName="editionTheme"
      />
    </div>
    <div class="form-field">
      <label for="editionDate">Mes de vigência (obrigatório)</label>
      <p-calendar
        view="month"
        formControlName="editionDateTime"
        dateFormat="yy.mm"
        id="editionDate"
      />
    </div>
  </p-panel>

  <p-panel
    header="Ícones das assinaturas - Máx 100kb | WEBP | Dimensão base: 70x70"
  >
    <div>
      <div>
        <p-fileUpload
          mode="basic"
          [chooseLabel]="'combo'"
          name="file"
          [auto]="true"
          [customUpload]="true"
          (onSelect)="
            handleFileSelect(
              $event,
              'fileUploadCombo',
              70,
              70,
              100 * 1024,
              fileUploadCombo,
              0
            )
          "
          accept="image/webp, image/gif"
          [maxFileSize]="1024000"
          uploadIcon="pi pi-file"
          id="fileUploadCombo"
          #fileUploadCombo
        />
        <div class="form-container__img">
          <img [src]="iconsArray[0].icon" />
        </div>
      </div>
      <div>
        <p-fileUpload
          mode="basic"
          [chooseLabel]="'box'"
          name="file"
          [auto]="true"
          [customUpload]="true"
          (onSelect)="
            handleFileSelect(
              $event,
              'fileUploadBox',
              70,
              70,
              100 * 1024,
              fileUploadBox,
              1
            )
          "
          accept="image/webp, image/gif"
          [maxFileSize]="1024000"
          uploadIcon="pi pi-file"
          id="fileUploadBox"
          #fileUploadBox
        />
        <div class="form-container__img">
          <img [src]="iconsArray[1].icon" />
        </div>
      </div>
      <div>
        <p-fileUpload
          mode="basic"
          [chooseLabel]="'bag'"
          name="file"
          [auto]="true"
          [customUpload]="true"
          (onSelect)="
            handleFileSelect(
              $event,
              'fileUploadBag',
              70,
              70,
              100 * 1024,
              fileUploadBag,
              2
            )
          "
          accept="image/webp, image/gif"
          [maxFileSize]="1024000"
          uploadIcon="pi pi-file"
          id="fileUploadBag"
          #fileUploadBag
        />
        <div class="form-container__img">
          <img [src]="iconsArray[2].icon" />
        </div>
      </div>
      <div>
        <p-fileUpload
          mode="basic"
          [chooseLabel]="'pass'"
          name="file"
          [auto]="true"
          [customUpload]="true"
          (onSelect)="
            handleFileSelect(
              $event,
              'fileUploadPass',
              70,
              70,
              100 * 1024,
              fileUploadPass,
              3
            )
          "
          accept="image/webp, image/gif"
          [maxFileSize]="1024000"
          uploadIcon="pi pi-file"
          id="fileUploadPass"
          #fileUploadPass
        />
        <div class="form-container__img">
          <img [src]="iconsArray[3].icon" />
        </div>
      </div>
    </div>
  </p-panel>

  <p-panel
    header="Cards da edição - Máx (120kb - 170kb)  | WEBP | Dimensão base: (345x104 - 488x109)"
  >
    <div class="form-field">
      <p-fileUpload
        mode="basic"
        [chooseLabel]="'Card da edição desktop'"
        name="file"
        [auto]="true"
        [customUpload]="true"
        (onSelect)="
          handleFileSelect(
            $event,
            'editionCardDesktop',
            488,
            109,
            170 * 1024,
            fileUploadCD
          )
        "
        accept="image/webp, image/gif"
        [maxFileSize]="174080"
        uploadIcon="pi pi-file"
        id="fileUploadCD"
        #fileUploadCD
      />
      <div class="form-container__img">
        <img [src]="form.get('editionCardDesktop').value" />
      </div>
    </div>
    <div class="form-field">
      <p-fileUpload
        mode="basic"
        [chooseLabel]="'Card da edição mobile'"
        name="file"
        [auto]="true"
        [customUpload]="true"
        (onSelect)="
          handleFileSelect(
            $event,
            'editionCardMobile',
            345,
            104,
            120 * 1024,
            fileUploadCM
          )
        "
        accept="image/webp, image/gif"
        [maxFileSize]="122880"
        uploadIcon="pi pi-file"
        id="fileUploadCM"
        #fileUploadCM
      />
      <div class="form-container__img">
        <img [src]="form.get('editionCardMobile').value" />
      </div>
    </div>
    <div class="form-field">
      <label>
        Cor do fundo do card
        <small>Apenas caso não tenha imagem cadastrada</small>
      </label>
      <p-colorPicker formControlName="editionCardBackgroundColor" />
    </div>
  </p-panel>

  <p-panel
    header="Card para o carrossel de últimas edições - Máx 170kb | WEBP | Dimensão base: 280x396"
  >
    <div class="form-field">
      <span class="label-container">
        <label>Alt text - máx. 125 caracteres</label>
      </span>
      <input
        id="lastEditionsCarouselCardAlt"
        type="text"
        pInputText
        formControlName="lastEditionsCarouselCardAlt"
      />
    </div>
    <div class="form-field">
      <p-fileUpload
        mode="basic"
        [chooseLabel]="'Últimas edições'"
        name="file"
        [auto]="true"
        [customUpload]="true"
        (onSelect)="
          handleFileSelect(
            $event,
            'lastEditionsCarouselCard',
            280,
            396,
            170 * 1024,
            fileUploadLast
          )
        "
        accept="image/webp, image/gif"
        [maxFileSize]="174080"
        uploadIcon="pi pi-file"
        id="fileUploadLast"
        #fileUploadLast
      />
      <div class="form-container__img">
        <img [src]="form.get('lastEditionsCarouselCard').value" />
      </div>
    </div>
  </p-panel>

  <div class="buttons">
    <p-button label="Voltar" severity="danger" [routerLink]="'..'" />
    <p-button label="Salvar" type="submit" [disabled]="form.invalid" />
  </div>
</form>
<p-toast />
