import { Clipboard } from '@angular/cdk/clipboard';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import { CardDifference, ItOpsControllerService } from 'src/app/admin-api';
import { SubscriberProblemGroupEnum } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-card-difference-modal',
  templateUrl: './card-difference-modal.component.html',
  styleUrls: ['./card-difference-modal.component.scss'],
  providers: [MessageService]
})
export class CardDifferenceModalComponent {
  recurrences: Array<CardDifference>;
  actualCardUpdate: string;

  constructor(
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private itOpsService: ItOpsControllerService,
    private clipboard: Clipboard,
    private messageService: MessageService
  ) {
    this.recurrences = this.config.data.recurrences;
    this.actualCardUpdate = this.recurrences?.reduce((sql, recurrence) => {
      const creditCardId =
        recurrence.correctCard;
        if(creditCardId)
      sql += `Exec [Glambox.Financial].dbo.CreditCardChange ${recurrence.subscriberId} , ${creditCardId};\n`;
      return sql;
    }, '');
  }

  async markAsResolved(): Promise<void> {
    LoaderService.showLoader();
    try {
      const dashboard = await lastValueFrom(
        this.itOpsService
          .markAsResolved(
            SubscriberProblemGroupEnum.CardDifference,
            this.recurrences
          )
          .pipe(map((data) => data.result))
      );
      this.ref.close(dashboard);
    } catch (error) {
      LoaderService.showLoader(false);
      AppDialogService.showErrorDialog(error);
    }
  }

  copyToClipboard(text: string): void {
    this.clipboard.copy(text);
    this.messageService.add({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Copiado para área de transferência'
    });
  }
}
