import { HttpClient } from '@angular/common/http';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';
import { Configuration } from './configuration';

import { AddressControllerService } from './api/addressController.service';
import { AdminControllerService } from './api/adminController.service';
import { ArticleControllerService } from './api/articleController.service';
import { BadgeControllerService } from './api/badgeController.service';
import { BeautyProfileAdminControllerService } from './api/beautyProfileAdminController.service';
import { BeautyProfileControllerService } from './api/beautyProfileController.service';
import { BlacklistControllerService } from './api/blacklistController.service';
import { BrandControllerService } from './api/brandController.service';
import { CompositionControllerService } from './api/compositionController.service';
import { CouponControllerService } from './api/couponController.service';
import { CouponPriceChangeControllerService } from './api/couponPriceChangeController.service';
import { CustomQueryControllerService } from './api/customQueryController.service';
import { EditionControllerService } from './api/editionController.service';
import { ErpControllerService } from './api/erpController.service';
import { FeedbackAdminControllerService } from './api/feedbackAdminController.service';
import { FeedbackControllerService } from './api/feedbackController.service';
import { FeedbackGroupControllerService } from './api/feedbackGroupController.service';
import { FileControllerService } from './api/fileController.service';
import { FinancialAdminControllerService } from './api/financialAdminController.service';
import { FinancialControllerService } from './api/financialController.service';
import { InfluencerControllerService } from './api/influencerController.service';
import { IntegrationControllerService } from './api/integrationController.service';
import { IntegrationTokenControllerService } from './api/integrationTokenController.service';
import { ItOpsControllerService } from './api/itOpsController.service';
import { KpiControllerService } from './api/kpiController.service';
import { MovementControllerService } from './api/movementController.service';
import { OpenControllerService } from './api/openController.service';
import { PaymentControllerService } from './api/paymentController.service';
import { PersonControllerService } from './api/personController.service';
import { ProductControllerService } from './api/productController.service';
import { RewardControllerService } from './api/rewardController.service';
import { SubscriberControllerService } from './api/subscriberController.service';
import { SubscriptionControllerService } from './api/subscriptionController.service';
import { SubscriptionTypeControllerService } from './api/subscriptionTypeController.service';
import { SubscriptionTypePriceControllerService } from './api/subscriptionTypePriceController.service';
import { UserControllerService } from './api/userController.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    AddressControllerService,
    AdminControllerService,
    ArticleControllerService,
    BadgeControllerService,
    BeautyProfileAdminControllerService,
    BeautyProfileControllerService,
    BlacklistControllerService,
    BrandControllerService,
    CompositionControllerService,
    CouponControllerService,
    CouponPriceChangeControllerService,
    CustomQueryControllerService,
    EditionControllerService,
    ErpControllerService,
    FeedbackAdminControllerService,
    FeedbackControllerService,
    FeedbackGroupControllerService,
    FileControllerService,
    FinancialAdminControllerService,
    FinancialControllerService,
    InfluencerControllerService,
    IntegrationControllerService,
    IntegrationTokenControllerService,
    ItOpsControllerService,
    KpiControllerService,
    MovementControllerService,
    OpenControllerService,
    PaymentControllerService,
    PersonControllerService,
    ProductControllerService,
    RewardControllerService,
    SubscriberControllerService,
    SubscriptionControllerService,
    SubscriptionTypeControllerService,
    SubscriptionTypePriceControllerService,
    UserControllerService
  ]
})
export class ApiModule {
  public static forRoot(
    configurationFactory: () => Configuration
  ): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }]
    };
  }

  constructor(
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error(
        'ApiModule is already loaded. Import in your base AppModule only.'
      );
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575'
      );
    }
  }
}
