/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { lastValueFrom, map } from 'rxjs';
import {
  FileControllerService,
  ProductControllerService,
  ProductImage,
  ProductImageRequest,
  ProductVariant,
  ProductVariantCreateRequest,
  ProductVariantUpdateRequest
} from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ImageUploadComponent } from '../../image-upload/image-upload.component';

@Component({
  selector: 'app-product-variant-form',
  templateUrl: './product-variant-form.component.html',
  styleUrls: ['./product-variant-form.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class ProductVariantFormComponent
  extends ImageUploadComponent<ProductVariant>
  implements OnInit
{
  @Input()
  product: ProductVariant | null | undefined;

  @Input()
  productId: number | undefined;

  @Output()
  buttonAction = new EventEmitter<ProductVariant | null>();

  @Input()
  images: Array<ProductImage> | undefined;

  @Input()
  productVariants: Array<ProductVariant> | undefined;

  minStock = 5;

  override form = new FormGroup({
    productVariantId: new FormControl<number>(
      {
        value: null,
        disabled: true
      },
      Validators.required
    ),
    productId: new FormControl<number>(null, Validators.required),
    productVariantName: new FormControl<string>(null, [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(255)
    ]),
    costPrice: new FormControl<number>(0),
    invoicePrice: new FormControl<number>(null),
    invoiceName: new FormControl<string>(null, [
      Validators.required,
      Validators.maxLength(255)
    ]),
    unitName: new FormControl<string>(null, [
      Validators.required,
      Validators.maxLength(50)
    ]),
    unitMeasure: new FormControl<number>(null, [
      Validators.required,
      Validators.min(1)
    ]),
    weight: new FormControl<number>(null, Validators.required),
    salePrice: new FormControl<number>(0),
    saleStock: new FormControl<number>(0),
    isSellable: new FormControl<boolean>(false),
    tradePoints: new FormControl<number>(null),
    maxTradesPerPerson: new FormControl<number>(0),
    tradeActive: new FormControl<boolean>(false),
    isTradeable: new FormControl<boolean>(false),
    defaultImageURL: new FormControl<string>(null),
    externalStoreUrl: new FormControl<string>(null),
    longExternalStoreUrl: new FormControl<string>(null),
    isSellableUrl: new FormControl<boolean>(null),
    wmsId: new FormControl<number>(null),
    displayOrder: new FormControl<number>(null),
    internalEAN: new FormControl<string>(null, [Validators.maxLength(15)]),
    externalId: new FormControl<number>(null),
    cean: new FormControl<string>(null, Validators.maxLength(14)),
    extipi: new FormControl<string>(null, Validators.maxLength(14)),
    ncm: new FormControl<string>(null, Validators.maxLength(50)),
    cst: new FormControl<string>(null, Validators.maxLength(50)),
    cfop: new FormControl<string>(null, Validators.maxLength(50)),
    genero: new FormControl<string>(null, Validators.maxLength(2)),
    expirationAttribute: new FormControl<string>(null)
  });

  constructor(
    private productService: ProductControllerService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    override router: Router,
    override fileService: FileControllerService
  ) {
    super(fileService, router);
  }

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    if (this.product) {
      this.form.controls.productVariantId.enable();
      this.form.patchValue({
        ...this.product,
        expirationAttribute: this.product.expiration
      });
    } else {
      this.form.controls.productId.setValue(this.productId);
    }
    LoaderService.showLoader(false);
  }

  changeTradeable(): void {
    this.form?.controls['isTradeable'].setValue(this.form.value.tradeActive);
    if (this.form?.value.tradeActive) {
      this.form?.controls['tradePoints'].setValidators([
        Validators.required,
        Validators.min(1)
      ]);
      this.form?.controls['maxTradesPerPerson'].setValidators([
        Validators.required,
        Validators.min(1)
      ]);
      this.form?.controls['maxTradesPerPerson'].setValue(
        this.form?.value.maxTradesPerPerson || 1
      );
    } else {
      this.form?.controls['tradePoints'].clearValidators();
      this.form?.controls['maxTradesPerPerson'].clearValidators();
    }
    this.form?.controls['tradePoints'].updateValueAndValidity();
    this.form?.controls['maxTradesPerPerson'].updateValueAndValidity();
  }

  onTradeActiveChange(): void {
    if (
      this.form?.value.tradeActive &&
      this.model &&
      (!this.model.inStockFidelization ||
        this.model.inStockFidelization <= this.minStock)
    ) {
      this.confirmationService.confirm({
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        acceptButtonStyleClass: 'p-button-danger',
        rejectButtonStyleClass: 'p-button-primary',
        closeOnEscape: false,
        message: `Estoque da variante: ${
          this.model.inStockFidelization
        }<br> Estoque mínimo exigido: ${
          this.minStock + 1
        }<br>O produto <b>NÃO</b> será exibido na vitrine!<br>Deseja continuar?`,
        accept: () => {
          this.changeTradeable();
        },
        reject: () => {
          this.form?.controls['tradeActive'].setValue(false);
        }
      });
    } else {
      this.changeTradeable();
    }
  }

  async submit(): Promise<void> {
    if (this.form?.valid) {
      LoaderService.showLoader();
      try {
        if (this.product?.productVariantId) {
          this.product = await lastValueFrom(
            this.productService
              .updateProductVariant({
                ...this.form.value
              } as ProductVariantUpdateRequest)
              .pipe(map((data) => data.result))
          );
          this.messageService.add({
            severity: 'success',
            detail: 'Variante atualizada com sucesso.',
            summary: 'Sucesso'
          });
        } else {
          this.product = await lastValueFrom(
            this.productService
              .createProductVariant(
                this.form.value as ProductVariantCreateRequest
              )
              .pipe(map((data) => data.result))
          );
          this.messageService.add({
            severity: 'success',
            detail: 'Variante cadastrada com sucesso.',
            summary: 'Sucesso'
          });
        }
        await this.saveProductImages();
        this.buttonAction.emit(this.product);
      } catch (error: any) {
        LoaderService.showLoader(false);
        AppDialogService.showErrorDialog(error);
      }
    }
  }

  imageVariant(productVariantId?: number): string {
    return (
      this.productVariants?.find((p) => p.productVariantId === productVariantId)
        ?.productVariantName || 'Sem variante'
    );
  }

  async saveProductImages(): Promise<void> {
    if (this.filesUploaded.length) {
      const images: Array<ProductImageRequest> = [];
      for (let index = 0; index < this.filesUploaded.length; index++) {
        images.push({
          imageUrl: this.filesUploaded[index],
          position: index,
          productId: this.productId,
          productVariantId: this.product?.productVariantId
        });
      }
      try {
        await Promise.all(
          images.map((i) =>
            lastValueFrom(this.productService.addProductImage(i))
          )
        );
        await this.afterSubmit();
      } catch (error: any) {
        AppDialogService.showErrorDialog(error);
      }
    }
  }

  get filePath(): string {
    return '/admin/products/';
  }

  override get model() {
    return this.product;
  }

  override get modelId() {
    return this.product?.productId || 0;
  }
}
