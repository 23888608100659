<app-table
  [addButton]="false"
  [cols]="cols"
  [findPage]="findPage"
  [service]="financialService"
  modelName="pagamentos"
  tableTitle="Pagamentos"
  sortField="dateCreated"
  [sortOrder]="1"
  [dropdownFilters]="dropdownFilters"
  [changeColumns]="true"
></app-table>
