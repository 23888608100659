import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  WritableSignal,
  inject,
  signal
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Order } from '@infrab4a/connect';
import { TableColumn } from 'src/app/components/table';
import { ShopOrderService } from 'src/app/connect-api/api/shop/shop-order.service';
import { ShopMap, getShop } from 'src/app/connect-api/enums/ShopMap';
import {
  PaymentMethod,
  getAllPaymentMethod
} from 'src/app/connect-api/models/PaymentMethod';
import {
  PaymentStatus,
  getAllPaymentStatus
} from 'src/app/connect-api/models/PaymentStatus';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-shop-orders',
  templateUrl: './shop-orders.component.html',
  styleUrl: './shop-orders.component.scss',
  providers: [DatePipe]
})
export class ShopOrdersComponent implements OnInit {
  shop: WritableSignal<ShopMap> = signal(undefined);
  protected shopOrdersService = inject(ShopOrderService);
  protected title = inject(Title);
  private activatedRoute = inject(ActivatedRoute);
  private cdRef = inject(ChangeDetectorRef);
  private datePipe = inject(DatePipe);

  protected orders: Array<Order>;
  protected originalCols = [
    new TableColumn.Builder()
      .setHeader('Id')
      .setField('orderNumber')
      .setRouterLink('/shop-orders/order/')
      .setRouterLinkFieldName('id')
      .build(),
    new TableColumn.Builder()
      .setHeader('Comprador')
      .setField('user.displayName')
      .setCondition('contains')
      .setDisplayFunction((order: Order) => `${order.user.displayName}`)
      .build(),
    new TableColumn.Builder()
      .setHeader('CPF')
      .setField('user.cpf')
      .setCondition('contains')
      .setDisplayFunction((order: Order) =>
        FormUtil.cpfFormatted(Number(order.user.cpf))
      )
      .build(),
    new TableColumn.Builder()
      .setHeader('Email')
      .setField('user.email')
      .setCondition('contains')
      .setDisplayFunction((order: Order) => order.user.email)
      .build(),
    new TableColumn.Builder()
      .setHeader('Forma Pagamento')
      .setField('payment.paymentMethod')
      .setCondition('in')
      .setDisplayFunction(
        (order: Order) => PaymentMethod[order.payment.paymentMethod]
      )
      .build(),
    new TableColumn.Builder()
      .setHeader('TID')
      .setField('payment.id')
      .setType('number')
      .setDisplayFunction((order: Order) => order.payment.id)
      .build(),
    new TableColumn.Builder()
      .setHeader('Status Pagamento')
      .setField('payment.status')
      .setCondition('in')
      .setDisplayFunction((order: Order) => PaymentStatus[order.payment.status])
      .build(),
    new TableColumn.Builder()
      .setHeader('Produtos')
      .setField('lineItems.length')
      .setType('formattedInteger')
      .setCondition('gte')
      .build(),
    new TableColumn.Builder()
      .setHeader('Loja')
      .setField('shop')
      .setCondition('in')
      .setDisplayFunction((order: Order) => ShopMap[order.shop])
      .build(),
    new TableColumn.Builder()
      .setHeader('Transportadora')
      .setField('shipping.ShippingCompanyName')
      .setCondition('contains')
      .setDisplayFunction((order: Order) => order.shipping?.ShippingCompanyName)
      .build(),
    new TableColumn.Builder()
      .setHeader('Status')
      .setField('status')
      .setCondition('in')
      .build(),
    new TableColumn.Builder()
      .setHeader('Cupom')
      .setField('coupon.nickname')
      .setDisplayFunction((order: Order) => order.coupon?.nickname)
      .build(),
    new TableColumn.Builder()
      .setHeader('Data')
      .setField('createdAt')
      .setType('date')
      .setCondition('gte')
      .setFilter(false)
      .build(),
    new TableColumn.Builder()
      .setHeader('Total')
      .setField('totalPrice')
      .setType('currency')
      .setCondition('gte')
      .build(),
    new TableColumn.Builder()
      .setHeader('Prazo')
      .setField('shipping.DaysToDelivery')
      .setDisplayFunction((order: Partial<Order>) =>
        order.shipping?.DaysToDelivery
          ? `${order.shipping.DaysToDelivery} dia(s)`
          : (order.shipping as any)?.description || 'Indeterminado'
      )
      .setCondition('gte')
      .build(),
    new TableColumn.Builder()
      .setHeader('Data entrega')
      .setField('shipping.DaysToDelivery')
      .setDisplayFunction((order: Partial<Order>) =>
        this.shopOrdersService.deliveryDate(order)
      )
      .setCondition('gte')
      .build()
  ];
  visibleColumns: TableColumn[];

  dropdownFilters = {
    status: [
      { label: 'Aguardando pagamento', value: 'Aguardando pagamento' },
      { label: 'Preparando pedido', value: 'Preparando pedido' },
      { label: 'Enviado', value: 'Enviado' },
      { label: 'Entregue', value: 'Entregue' },
      { label: 'Cancelado', value: 'Cancelado' }
    ],
    shop: [
      { label: ShopMap.Glamshop, value: 'Glamshop' },
      { label: ShopMap.Glampoints, value: 'Glampoints' },
      { label: ShopMap.mensmarket, value: 'mensmarket' }
    ],
    'payment.paymentMethod': getAllPaymentMethod(),
    'payment.status': getAllPaymentStatus()
  };
  referenceForm = signal(
    new FormGroup({
      dateIni: new FormControl(new Date(), Validators.required),
      columns: new FormControl<Array<TableColumn>>([], Validators.minLength(1))
    })
  );
  totalCols = 0;

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    const defaultDate = FormUtil.utcDate(new Date());
    defaultDate.setDate(defaultDate.getDate() - 1);
    defaultDate.setMinutes(0);
    defaultDate.setSeconds(0);
    defaultDate.setMilliseconds(0);
    defaultDate.setHours(-3);
    this.referenceForm.update((form) => {
      form.controls['dateIni'].setValue(defaultDate);
      return form;
    });
    this.activatedRoute.params.subscribe(async (params) => {
      if (params['shop']) {
        this.shop.set(params['shop']);
      } else {
        this.shop.set(undefined);
      }
      this.title.setTitle(
        'Pedidos da Loja' + (this.shop() ? ' - ' + this.shopName : '')
      );
      this.visibleColumns = [...this.validColumns];
      this.referenceForm.update((form) => {
        form.controls['columns'].setValue(this.validColumns);
        return form;
      });
      await this.findOrders();
      LoaderService.showLoader(false);
    });
  }

  async findOrders(date?: Date): Promise<void> {
    try {
      const dateIni = FormUtil.utcDate(
        date || this.referenceForm().value.dateIni
      );
      dateIni.setHours(-3);
      dateIni.setMinutes(0);
      dateIni.setSeconds(0);
      dateIni.setMilliseconds(0);
      this.orders = await this.shopOrdersService.getOrders(
        new Date(dateIni.getTime()),
        this.shop()
      );
    } catch (error) {
      this.orders = [];
      AppDialogService.showErrorDialog(error);
    }
  }

  async reloadTable(refresh = true): Promise<void> {
    if (refresh) {
      LoaderService.showLoader();
      await this.findOrders();
      LoaderService.showLoader(false);
    } else if (
      refresh ||
      (this.referenceForm().value.columns?.length &&
        (this.referenceForm().value.columns.length !==
          this.visibleColumns.length ||
          this.referenceForm().value.columns.some((c) =>
            this.visibleColumns.every((cd) => cd.field !== c.field)
          )))
    ) {
      LoaderService.showLoader();
      const orders = [...this.orders];
      delete this.orders;
      setTimeout(() => {
        this.visibleColumns = this.validColumns.filter((c) =>
          this.referenceForm().value.columns.some((rc) => rc.field === c.field)
        );
        this.orders = [...orders];
        LoaderService.showLoader(false);
      }, 1000);
    }
  }

  updateTotalCols() {
    this.totalCols = this.visibleColumns?.length || 0;
    this.cdRef.detectChanges();
  }

  get columnsLabel(): string {
    return this.referenceForm().value.columns.length ===
      this.validColumns?.length
      ? 'Todas'
      : `${this.referenceForm().value.columns.length} colunas`;
  }

  get shopName(): string {
    return this.shop() !== undefined ? ShopMap[getShop(this.shop())] : null;
  }

  get validColumns(): Array<TableColumn> {
    return this.shop()
      ? this.originalCols.filter((c) => c.field !== 'shop')
      : this.originalCols;
  }
}
