<div>
  <div *ngIf="!article" class="page-title">Novo artigo</div>
  <div *ngIf="article" class="page-title">
    <ng-container *ngIf="permission"> Editar </ng-container>
    <ng-container *ngIf="!permission"> Visualizar </ng-container>
    artigo
  </div>
</div>

<form [formGroup]="form" (submit)="submit()" class="form-b4a" *ngIf="form">
  <span class="p-float-label">
    <input
      id="articleTitle"
      type="text"
      pInputText
      formControlName="articleTitle"
    />
    <label for="articleTitle">Nome do artigo *</label>
    <small
      *ngIf="articleTitle.invalid && articleTitle.touched && articleTitle.dirty"
      class="error"
    >
      Nome do artigo é obrigatório
    </small>
    <small class="hint" [class.danger]="!form.controls.articleTitle.valid">
      {{ form.value.articleTitle?.trim().length || 0 | number : '1.0-0' }}/800
    </small>
  </span>

  <span class="input-switch">
    <label for="active">Inativo</label>
    <p-inputSwitch formControlName="active" inputId="active" />
    <label for="active">Ativo</label>
  </span>

  <span class="p-float-label">
    <input
      id="articleSummary"
      type="text"
      pInputText
      formControlName="articleSummary"
    />
    <label for="articleSummary">Subtítulo do artigo *</label>
    <small
      *ngIf="
        articleSummary.invalid && articleSummary.touched && articleSummary.dirty
      "
      class="error"
    >
      Subtítulo do artigo é obrigatório
    </small>
    <small class="hint" [class.danger]="!form.controls.articleSummary.valid">
      {{
        form.value.articleSummary?.trim().length || 0 | number : '1.0-0'
      }}/4000
    </small>
  </span>

  <span class="p-float-label">
    <input
      id="articleAuthor"
      type="text"
      pInputText
      formControlName="articleAuthor"
    />
    <label for="articleAuthor">Autor do artigo</label>
  </span>

  <span class="p-float-label">
    <input
      id="articleDescription"
      type="text"
      pInputText
      formControlName="articleDescription"
    />
    <label for="articleDescription">Meta description</label>
    <small
      class="hint"
      [class.danger]="!form.controls.articleDescription.valid"
    >
      {{
        form.value.articleDescription?.trim().length || 0 | number : '1.0-0'
      }}/4000
    </small>
  </span>

  <span class="p-float-label">
    <input
      id="articleKeywords"
      type="text"
      pInputText
      formControlName="articleKeywords"
    />
    <label for="articleKeywords">Palavras-chave do artigo</label>
  </span>

  <span class="p-float-label">
    <p-calendar
      formControlName="publishDate"
      inputId="publishDate"
      dateFormat="dd/mm/yy"
    />
    <label for="publishDate">Data de publicação</label>
    <small
      class="error"
      *ngIf="
        form.controls['publishDate'].dirty &&
        form.controls['publishDate'].errors &&
        form.controls['publishDate'].errors['invalidDate']
      "
    >
      {{ form.controls['publishDate'].errors['invalidDate'] }}
    </small>
  </span>

  <span class="p-float-label">
    <p-calendar
      formControlName="unpublishDate"
      inputId="unpublishDate"
      dateFormat="dd/mm/yy"
    />
    <label for="unpublishDate">Data de despublicação</label>
    <small
      class="error"
      *ngIf="
        form.controls['unpublishDate'].dirty &&
        form.controls['unpublishDate'].errors &&
        form.controls['unpublishDate'].errors['invalidDate']
      "
    >
      {{ form.controls['unpublishDate'].errors['invalidDate'] }}
    </small>
  </span>

  <span class="p-float-label">
    <ng-container>
      <p-dropdown
        formControlName="articleType"
        inputId="articleType"
        [options]="allArticleType"
        optionLabel="articleTypeName"
        optionValue="articleTypeId"
      />
      <label for="articleType">Tipo do artigo *</label>
    </ng-container>
  </span>

  <span class="p-float-label">
    <input
      id="articleTags"
      type="text"
      pInputText
      formControlName="articleTags"
    />
    <label for="articleTags">Tags do artigo</label>
    <small class="hint" [class.danger]="!form.controls.articleTags.valid">
      {{ form.value.articleTags?.trim().length || 0 | number : '1.0-0' }}/4000
    </small>
  </span>

  <span class="p-input-editor">
    <span class="label-option">
      <label for="articleBody">Corpo do Artigo </label>
      <span class="p-input-checbox">
        <p-checkbox
          formControlName="html"
          [binary]="true"
          inputId="html"
          name="html"
          (onChange)="format()"
        />
        <label for="html">Ver código fonte</label>
      </span>
    </span>
    <p-editor
      [readonly]="!permission"
      formControlName="articleBody"
      id="articleBody"
      (keyup)="cdRef.detectChanges()"
      (click)="cdRef.detectChanges()"
      [hidden]="form.value.html"
      [modules]="{
        toolbar: { handlers, container: toolbarOptions },
        blotFormatter2: {
          resize: {
            handleClassName: 'dimensioned'
          }
        }
      }"
    >
      <ng-template pTemplate="header" />
    </p-editor>
    <textarea
      name="articleBody"
      id="articleBody"
      formControlName="articleBody"
      *ngIf="form.value.html"
      rows="50"
    >
    </textarea>
    <small class="hint" [class.danger]="!form.controls.articleBody.valid">
      {{ form.value.articleBody?.trim().length || 0 | number : '1.0-0' }}/50.000
    </small>
  </span>

  <span class="file-upload" *ngIf="!images || !images.length">
    <label for="articleImage">
      <span>Imagem padrão</span>
      <img
        [src]="form.value?.articleImage"
        *ngIf="form.value?.articleImage"
        alt="Imagem padrão"
      />
      <img
        src="assets/images/no_img_available.jpg"
        *ngIf="!form.value?.articleImage"
        alt="Imagem padrão"
      />
    </label>
    <p-fileUpload
      mode="basic"
      chooseLabel="Selecionar"
      name="file"
      [auto]="true"
      accept="image/*"
      [maxFileSize]="1000000"
      uploadIcon="pi pi-file"
      [customUpload]="true"
      (uploadHandler)="cropImageBeforeUpload($event, fileUpload)"
      id="articleImage"
      #fileUpload
      [disabled]="!permission"
    />
  </span>

  <small *ngIf="article?.dateUpdated">
    *Última alteração em:
    {{ article.dateUpdated | date : 'dd/MM/yyyy HH:mm:ss' }}
  </small>

  <div class="buttons" *ngIf="permission">
    <p-button label="Salvar" type="submit" [disabled]="!form.valid" />
  </div>
</form>
<input type="file" hidden id="inputFile" (change)="handleFile($event)" />
