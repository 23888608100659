import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationService, MessageService, PrimeIcons } from 'primeng/api';
import { lastValueFrom, map } from 'rxjs';
import {
  InstagramUser,
  PersonControllerService,
  PersonDetail
} from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';
import { environment } from 'src/environments/environment';
import { TableActionButton, TableColumn } from '../table';

@Component({
  selector: 'app-person-instagram-history',
  templateUrl: './person-instagram-history.component.html',
  styleUrls: ['./person-instagram-history.component.scss'],
  providers: [DatePipe, ConfirmationService, MessageService]
})
export class PersonInstagramHistoryComponent implements OnInit {
  @Input()
  person: PersonDetail | undefined;

  @Input()
  history: Array<InstagramUser & { status?: string }> | undefined;

  @Input()
  isMobile?: boolean;

  @Output()
  refresh = new EventEmitter<void>();

  cols: Array<TableColumn> = [
    new TableColumn(
      'Usuário',
      'username',
      false,
      'text',
      environment.instagramUrl,
      'username'
    ),
    new TableColumn('Criado em', 'dateCreated', false, 'date'),
    new TableColumn(
      'Status',
      'status',
      false,
      'status',
      undefined,
      undefined,
      true,
      'equals',
      undefined,
      this.rowActive
    ),
    new TableColumn('Ações', '', false, 'button')
  ];

  actionButtons: Array<TableActionButton> = [
    new TableActionButton(
      '',
      'inactivate',
      PrimeIcons.TIMES_CIRCLE,
      this.rowActive,
      '',
      'Desativar',
      'bottom',
      true,
      true,
      'danger'
    ),
    new TableActionButton(
      '',
      'delete',
      PrimeIcons.TRASH,
      this.rowInactive,
      '',
      'Excluir',
      'bottom',
      true,
      true,
      'danger'
    )
  ];

  constructor(
    private datePipe: DatePipe,
    private confirmationService: ConfirmationService,
    private personService: PersonControllerService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.history.forEach((h) => (h.status = h.isActive ? 'Ativo' : 'Inativo'));
  }

  getStatus(isActive?: boolean): string {
    return isActive ? 'Ativo' : 'Inativo';
  }

  timeDiffLabel(dateStart: string): string {
    return FormUtil.timeDiffLabel(dateStart, this.datePipe);
  }

  instagramUrl(username: string): string {
    return environment.instagramUrl + username;
  }

  rowActive(row: InstagramUser): boolean {
    return row.isActive;
  }

  rowInactive(row: InstagramUser): boolean {
    return !row.isActive;
  }

  handleEvent($event: { $event: Event; item: InstagramUser; action: string }) {
    if ($event.action === 'inactivate' || $event.action === 'delete') {
      this[$event.action]($event.item, $event.$event);
    }
  }

  inactivate(instagram: InstagramUser, event: Event) {
    this.confirmationService.confirm({
      target: event.target,
      message: `Desejar inativar o vínculo com a conta @${instagram?.username}?`,
      acceptLabel: 'Sim',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Voltar',
      rejectButtonStyleClass: 'p-button-primary',
      accept: async () => {
        LoaderService.showLoader();
        try {
          await lastValueFrom(
            this.personService
              .inactivateInstagramUser(
                instagram?.personId as number,
                instagram?.userCode as string
              )
              .pipe(map((data) => data.result))
          );
          instagram.isActive = false;
        } catch (error: any) {
          AppDialogService.showErrorDialog(error);
        }
        LoaderService.showLoader(false);
      }
    });
  }

  delete(instagram: InstagramUser, $event: Event) {
    this.confirmationService.confirm({
      target: $event.target,
      message: `Desejar remover o vínculo da conta @${instagram.username}?`,
      acceptLabel: 'Sim',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Voltar',
      accept: async () => {
        LoaderService.showLoader();
        try {
          const result = await lastValueFrom(
            this.personService
              .deleteInstagramUser(
                instagram?.personId as number,
                instagram?.userCode as string
              )
              .pipe(map((data) => data.result))
          );
          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: result || 'Conta removida com sucesso.'
          });
          this.refresh.emit();
        } catch (error: any) {
          AppDialogService.showErrorDialog(
            error,
            false,
            'Não foi possível excluir esta conta.'
          );
        }
        LoaderService.showLoader(false);
      }
    });
  }
}
