import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { ChartModule } from 'primeng/chart';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { AppTableModule } from 'src/app/components/table/app-table.module';
import { FailExportComponent } from './fail-export/fail-export.component';
import { IntegrationRoutingModule } from './integration-routing.module';
import { IntegrationTokenFormComponent } from './integration-tokens/integration-token-form/integration-token-form.component';
import { IntegrationTokensComponent } from './integration-tokens/integration-tokens.component';
import { IntegrationComponent } from './integration.component';
import { UpdateShippingCompanyComponent } from './update-shipping-company/update-shipping-company.component';
import { UpdateShippingCompanyActivationComponent } from './update-shipping-company-activation/update-shipping-company-activation.component';

@NgModule({
  declarations: [
    IntegrationComponent,
    FailExportComponent,
    UpdateShippingCompanyComponent,
    IntegrationTokensComponent,
    IntegrationTokenFormComponent,
    UpdateShippingCompanyActivationComponent
  ],
  imports: [
    CommonModule,
    IntegrationRoutingModule,
    ReactiveFormsModule,
    DropdownModule,
    ChartModule,
    AppTableModule,
    ButtonModule,
    TooltipModule,
    ToastModule,
    ConfirmPopupModule,
    MultiSelectModule,
    InputTextModule,
    InputTextareaModule,
    TabViewModule
  ]
})
export class IntegrationModule {}
