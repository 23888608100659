<div class="consolidated-card consolidated-card--header">
  <img src="assets/images/Logo_B4A_White.png" alt="B4A" />
  <span class="title">Consolidado de Lojas e Assinaturas - AO VIVO</span>
</div>
<div class="totals">
  <div class="consolidated-card">
    <span class="header">Total Assinaturas glam + Clube Men's</span>
    <span class="value" *ngIf="subscriptionsTotal && subscriptionsTotal()">{{
      subscriptionsTotal()?.total || 0 | number : '1.0-0'
    }}</span>
  </div>
  <div class="consolidated-card">
    <span class="header">Receita Lojas glam + Men's MKT</span>
    <span class="value">{{ shopIncome()?.total || 0 | currency : 'BRL' }}</span>
  </div>
  <div class="consolidated-card">
    <span class="header">Receita Marcas Próprias</span>
    <span class="value">{{
      ownBrandsIncome()?.total || 0 | currency : 'BRL'
    }}</span>
  </div>
</div>
<div class="totals">
  <div class="consolidated-card">
    <span class="header">Horário Última Assinatura​</span>
    <span class="value-date">
      <ng-container
        *ngIf="subscriptionsTotal && subscriptionsTotal()?.createdAt"
      >
        {{
          subscriptionsTotal()?.createdAt | date : 'dd/MM/yyyy HH' : 'UTC'
        }}h{{ subscriptionsTotal()?.createdAt | date : 'mm' }}
      </ng-container>
      <ng-container *ngIf="!subscriptionsTotal()?.createdAt"> -- </ng-container>
    </span>
  </div>
  <div class="consolidated-card">
    <span class="header">Horário Última Venda​</span>
    <span class="value-date">
      <ng-container *ngIf="shopIncome()?.createdAt">
        {{ shopIncome()?.createdAt | date : 'dd/MM/yyyy HH' }}h{{
          shopIncome()?.createdAt | date : 'mm'
        }}
      </ng-container>
      <ng-container *ngIf="!shopIncome()?.createdAt"> -- </ng-container>
    </span>
  </div>
  <div class="consolidated-card">
    <span class="header">Horário Última Venda​</span>
    <span class="value-date">
      <ng-container *ngIf="ownBrandsIncome()?.createdAt">
        {{ ownBrandsIncome()?.createdAt | date : 'dd/MM/yyyy HH' }}h{{
          ownBrandsIncome()?.createdAt | date : 'mm'
        }}
      </ng-container>
      <ng-container *ngIf="!ownBrandsIncome()?.createdAt"> -- </ng-container>
    </span>
  </div>
</div>
