// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //apiUrl: 'http://localhost:5000',
  apiUrl: 'https://admin-api-homolog.b4a.com.br',
  subscriptionApiUrl: 'https://subscription-api-homolog.b4a.com.br/',
  instagramUrl: 'https://instagram.com/',
  firebase: {
    apiKey: 'AIzaSyDSdCxzDqkGXwCRSBeWfkDf_AWgp7u1P_c',
    authDomain: 'b4a-prj-admin-prd.firebaseapp.com',
    projectId: 'b4a-prj-admin-prd',
    storageBucket: 'b4a-prj-admin-prd.appspot.com',
    messagingSenderId: '613925779981',
    appId: '1:613925779981:web:60cd13886f32c752493344',
    vapidKey:
      'BHgakBmvRNpDgHHl0sdJCFIwiOGovvuNA0itG4rnm-dmMihRoAo8iF0A7lu0WvjRnrnCliAvVZmc8kLKMRPmgaM',
    serverKey: 'HvnC9Y9pI6_4w4H1B-UDoGMl0uZsE85g_N5PsLhpPB0'
  },
  connectFirebase: {
    apiKey: 'AIzaSyD1b7E5f4UT-aUmsWPoBGL3iTBocZRyMes',
    authDomain: 'b4aunified.firebaseapp.com',
    projectId: 'b4aunified',
    storageBucket: 'b4aunified.appspot.com',
    messagingSenderId: '331394878818',
    appId: '1:331394878818:web:12e98db920a9feb0c9303b',
    locationId: 'southamerica-east1'
  },
  elasticSearch: {
    url: 'https://a2bb4426e59548a78607bb5a78c4e8cc.us-central1.gcp.cloud.es.io:443',
    credential: 'TzNYS2RZY0J0M3dXQS1YS3VsY006Vkx6aG9mMDJTSzZGOEt4Vk9ZcjVKZw=='
  },
  hasura: {
    endpoint:
      'https://hasura-products-unified-36m4jjtkna-rj.a.run.app/v1/graphql',
    adminSecret: 'b171ab4d722455b03648ed462b41f8c2'
  },
  firebaseRegion: 'southamerica-east1',
  auditApiUrl: 'https://audit-microservice-dot-b4astage-cb80a.rj.r.appspot.com',
  glamUrl: 'https://glam-homolog.web.app'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
