import { DecimalPipe } from '@angular/common';
import { Component, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Listbox, ListboxFilterEvent } from 'primeng/listbox';
import { lastValueFrom, map } from 'rxjs';
import {
  ProductControllerService,
  ProductVariantSummary
} from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-product-variant-select-modal',
  templateUrl: './product-variant-select-modal.component.html',
  styleUrl: './product-variant-select-modal.component.scss',
  encapsulation: ViewEncapsulation.None,
  providers: [DecimalPipe]
})
export class ProductVariantSelectModalComponent {
  @ViewChild(Listbox) listBox: Listbox | undefined;

  private ref: DynamicDialogRef = inject(DynamicDialogRef);
  private productService: ProductControllerService = inject(
    ProductControllerService
  );
  private decimalPipe = inject(DecimalPipe);

  product = new FormControl<ProductVariantSummary>(null, [Validators.required]);
  products: Array<ProductVariantSummary> = [];
  searchValid = false;
  search = '';
  loading = false;
  searching = false;

  async submit(): Promise<void> {
    this.ref.close(this.product.value);
  }

  async findProducts(search?: string): Promise<void> {
    if (!search?.trim().length && !this.product?.value) return;
    try {
      this.products =
        (await lastValueFrom(
          this.productService
            .searchVariantsByNameOrExternalIdOrBrandOrInternalEAN(search)
            .pipe(
              map((data) => {
                data.result = data.result.map((r) => ({
                  ...r,
                  productVariantName: `${r.productVariantName} ${
                    r.internalEAN ? '[EAN:' + r.internalEAN + ']' : ''
                  } ${r.externalId ? '(SKU:' + r.externalId + ')' : ''} - ${
                    r.brandName
                  }  (Estoque: ${this.decimalPipe.transform(
                    r.inStockReserveMarketing || 0,
                    '1.0-0'
                  )})`
                }));
                data.result.sort((p1, p2) => {
                  return (
                    FormUtil.semAcento(p1.productVariantName)
                      .replace("'", '')
                      .toLowerCase()
                      .localeCompare(
                        FormUtil.semAcento(p2.productVariantName)
                          .replace("'", '')
                          .toLowerCase()
                      ) || p1.productVariantId - p2.productVariantId
                  );
                });
                return data.result
                  .filter((p) => p.inStockReserveMarketing > 0)
                  .concat(
                    data.result.filter(
                      (p) =>
                        !p.inStockReserveMarketing ||
                        p.inStockReserveMarketing < 0
                    )
                  );
              })
            )
        )) || [];
    } catch (error: any) {
      this.products = [];
      AppDialogService.showErrorDialog(error);
    }
  }

  filter($event: ListboxFilterEvent): void {
    this.searchValid = $event.filter?.length > 2;
    this.search = $event.filter;
    if (this.searchValid) {
      this.loading = true;
      setTimeout(async () => {
        if (this.search === $event.filter && !this.searching) {
          this.searching = true;
          await this.findProducts(this.search);
          this.loading = false;
          this.searching = false;
        }
      }, 500);
    } else {
      this.products = [];
    }
  }
}
