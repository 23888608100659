import {
  Component,
  computed,
  inject,
  Input,
  OnInit,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import {
  OpenControllerService,
  ShopIncomeResponse,
  SubscriptionsTotalResponse
} from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-consolidated-dashboard',
  templateUrl: './consolidated-dashboard.component.html',
  styleUrl: './consolidated-dashboard.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ConsolidatedDashboardComponent implements OnInit {
  @Input()
  glamSubscriptions: WritableSignal<SubscriptionsTotalResponse> =
    signal(undefined);

  private openService = inject(OpenControllerService);

  shopIncome: WritableSignal<ShopIncomeResponse> = signal(undefined);
  ownBrandsIncome: WritableSignal<ShopIncomeResponse> = signal(undefined);
  mensSubscriptions: WritableSignal<number> = signal(undefined);
  subscriptionsTotal = computed(() => {
    return {
      total:
        (this.glamSubscriptions ? this.glamSubscriptions()?.total || 0 : 0) +
        (this.mensSubscriptions() || 0),
      createdAt: this.glamSubscriptions
        ? this.glamSubscriptions()?.createdAt
        : null
    } as SubscriptionsTotalResponse;
  });

  async ngOnInit() {
    await this.findPage();
  }

  async findPage() {
    LoaderService.showLoader();
    await Promise.all([
      this.findShopIncome(),
      this.findOwnBrandsIncome(),
      this.findMensSubscriptions()
    ]);
    LoaderService.showLoader(false);
  }

  async findShopIncome() {
    try {
      this.shopIncome.set(
        await lastValueFrom(
          this.openService.findShopIncome().pipe(
            map((data) => ({
              ...data.result,
              createdAt: FormUtil.utcDate(data.result.createdAt)
            }))
          )
        )
      );
    } catch (error) {
      this.shopIncome.set({
        total: 0
      });
      AppDialogService.showErrorDialog(error);
    }
  }

  async findOwnBrandsIncome() {
    try {
      this.ownBrandsIncome.set(
        await lastValueFrom(
          this.openService.findOwnBrandsIncome().pipe(
            map((data) => ({
              ...data.result,
              createdAt: FormUtil.utcDate(data.result.createdAt)
            }))
          )
        )
      );
    } catch (error) {
      this.ownBrandsIncome.set({
        total: 0
      });
      AppDialogService.showErrorDialog(error);
    }
  }

  async findMensSubscriptions() {
    try {
      this.mensSubscriptions.set(
        await lastValueFrom(
          this.openService
            .findMensSubscriptions()
            .pipe(map((data) => data.result))
        )
      );
    } catch (error) {
      this.mensSubscriptions.set(0);
      AppDialogService.showErrorDialog(error);
    }
  }
}
