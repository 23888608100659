export class TableActionButton {
  label: string;
  action: string;
  icon: string;
  display: (item: any) => boolean;
  styleClass: string;
  tooltip: string;
  tooltipPosition = 'bottom';
  rounded: boolean;
  text: boolean;
  severity: string;
  size: 'small' | 'large' | null;

  constructor(
    label: string,
    action: string,
    icon: string,
    display: (item: any) => boolean,
    styleClass: string,
    tooltip = '',
    tooltipPosition = 'bottom',
    rounded = false,
    text = false,
    severity?: string,
    size: 'small' | 'large' | null = null
  ) {
    this.label = label;
    this.action = action;
    this.icon = icon;
    this.styleClass = styleClass;
    if (display !== undefined && display !== null) this.display = display;
    else this.display = () => true;
    this.tooltip = tooltip;
    this.tooltipPosition = tooltipPosition;
    this.rounded = rounded;
    this.text = text;
    this.severity = severity;
    this.size = size;
  }

  static Builder = class {
    label: string;
    action: string;
    icon: string;
    display: (item: any) => boolean;
    styleClass: string;
    tooltip: string;
    tooltipPosition = 'bottom';
    rounded: boolean;
    text: boolean;
    severity: string;
    size: 'small' | 'large' | null;

    setLabel(label: string) {
      this.label = label;
      return this;
    }
    setAction(action: string) {
      this.action = action;
      return this;
    }
    setIcon(icon: string) {
      this.icon = icon;
      return this;
    }
    setDisplay(display: (item: any) => boolean) {
      this.display = display;
      return this;
    }
    setStyleClass(styleClass: string) {
      this.styleClass = styleClass;
      return this;
    }
    setTooltip(tooltip: string) {
      this.tooltip = tooltip;
      return this;
    }
    setTooltipPosition(tooltipPosition: string) {
      this.tooltipPosition = tooltipPosition;
      return this;
    }
    setRounded(rounded: boolean) {
      this.rounded = rounded;
      return this;
    }
    setText(text: boolean) {
      this.text = text;
      return this;
    }
    setSeverity(severity: string) {
      this.severity = severity;
      return this;
    }
    setSize(size: 'small' | 'large' | null) {
      this.size = size;
      return this;
    }
    build() {
      const column = new TableActionButton(
        this.label,
        this.action,
        this.icon,
        this.display,
        this.styleClass,
        this.tooltip,
        this.tooltipPosition,
        this.rounded,
        this.text,
        this.severity,
        this.size
      );
      return column;
    }
  };
}
