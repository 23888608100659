import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { lastValueFrom, map } from 'rxjs';
import {
  CreditCardPaymentSummary,
  FinancialControllerService,
  Gateway,
  PageableRequest,
  TransactionStatus
} from 'src/app/admin-api';
import {
  DropdownFilter,
  PageContent,
  TableColumn
} from 'src/app/components/table';
import { AppDialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {
  cols: Array<TableColumn> = [
    new TableColumn(
      'Id assinatura',
      'subscriberId',
      true,
      'number',
      '/users/subscribers/',
      'subscriberId'
    ),
    new TableColumn(
      'Assinante',
      'personName',
      true,
      'text',
      '/users/person/',
      'personId',
      true,
      'contains'
    ),
    new TableColumn('TID', 'tid', true, 'text', undefined, undefined, true),
    new TableColumn(
      'Gateway',
      'gatewayName',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn('Valor', 'amount', false, 'currency'),
    new TableColumn('Parcelas', 'installments', false, 'text'),
    new TableColumn(
      'Data',
      'dateCreated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    ),
    new TableColumn('Status', 'transactionStatusName', true, 'number'),
    new TableColumn('Detalhe', 'authorizationMessage', true, 'text'),
    new TableColumn('Tipo', 'creditCardPaymentType', true, 'text')
  ];
  dropdownFilters: {
    [field: string]: Array<DropdownFilter>;
  } = {
    transactionStatusName: []
  };
  transactionStatusList: Array<TransactionStatus> | undefined;
  gateways: Array<Gateway> | undefined;

  constructor(
    public financialService: FinancialControllerService,
    private title: Title
  ) {
    this.title.setTitle('Pagamentos');
  }

  async ngOnInit(): Promise<void> {
    await Promise.all([
      this.findTransactionStatusList(),
      this.findGatewayList()
    ]);
  }

  async findTransactionStatusList(): Promise<void> {
    try {
      this.transactionStatusList = await lastValueFrom(
        this.financialService
          .findTransactionStatusList()
          .pipe(map((data) => data.result))
      );
      this.dropdownFilters['transactionStatusName'] = [
        { label: 'Todos', value: '-1' }
      ].concat(
        this.transactionStatusList?.map(
          (t) =>
            ({
              label: t.transactionStatusName,
              value: t.transactionStatusId?.toString()
            } as { label: string; value: string })
        ) || []
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findGatewayList(): Promise<void> {
    try {
      this.gateways = await lastValueFrom(
        this.financialService
          .findGatewaysList()
          .pipe(map((data) => data.result))
      );
      this.dropdownFilters['gatewayName'] = [
        { label: 'Todos', value: '-1' }
      ].concat(
        this.gateways?.map(
          (g) =>
            ({
              label: g.gatewayName,
              value: g.gatewayId?.toString()
            } as { label: string; value: string })
        ) || []
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findPage(
    request: PageableRequest,
    service: unknown
  ): Promise<PageContent<CreditCardPaymentSummary>> {
    const filterTransaction = request.filters?.find(
      (f) => f.field === 'transactionStatusName'
    );
    if (filterTransaction?.value) {
      if (Number(filterTransaction.value) < 0) {
        filterTransaction.value = '';
      }
      request.filters?.push({
        condition: 'equals',
        field: 'transactionStatusId',
        fieldType: 'number',
        value: filterTransaction.value
      });
    }
    const filterGateway = request.filters?.find(
      (f) => f.field === 'gatewayName'
    );
    if (filterGateway?.value) {
      if (Number(filterGateway.value) < 0) {
        filterGateway.value = '';
      }
      request.filters?.push({
        condition: 'equals',
        field: 'gatewayId',
        fieldType: 'number',
        value: filterGateway.value
      });
    }
    return lastValueFrom(
      (service as FinancialControllerService)
        .findPaymentTable(request)
        .pipe(
          map((data) => data.result as PageContent<CreditCardPaymentSummary>)
        )
    );
  }
}
