import {
  Component,
  OnDestroy,
  OnInit,
  WritableSignal,
  computed,
  inject,
  signal
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { lastValueFrom, map } from 'rxjs';
import {
  IntegrationControllerService,
  WeightRangeServiceCompanyRange
} from 'src/app/admin-api';
import { TableColumn } from 'src/app/components/table';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-update-shipping-company-activation',
  templateUrl: './update-shipping-company-activation.component.html',
  styleUrl: './update-shipping-company-activation.component.scss'
})
export class UpdateShippingCompanyActivationComponent
  implements OnInit, OnDestroy
{
  private integrationService: IntegrationControllerService = inject(
    IntegrationControllerService
  );
  private title = inject(Title);

  weightRangeServiceCompanyRange: WritableSignal<
    WeightRangeServiceCompanyRange[]
  > = signal(undefined);
  logger: WeightRangeServiceCompanyRange[];

  cancelButtonDisabled: boolean = true;
  syncButtonDisabled: boolean = true;
  updateButtonDisabled: boolean = true;
  rollbackButtonDisabled: boolean = true;
  continueButtonDisabled: boolean = true;
  stopButtonDisabled: boolean = true;

  private weightRangeIntervalId: any;
  private loggerIntervalId: any;

  synchronism = computed(() =>
    this.weightRangeServiceCompanyRange()?.some(
      (item) => item.status === 'PENDING'
    )
  );

  async ngOnInit(): Promise<void> {
    this.title.setTitle('Integrações - Atualização da tabela de frete de Loja');

    await this.fetch();

    this.weightRangeIntervalId = setInterval(() => {
      this.fetchUpdateWeightRange();
    }, 12000);

    this.loggerIntervalId = setInterval(() => {
      this.fetchUpdateLogger();
    }, 15000);
  }

  ngOnDestroy(): void {
    if (this.weightRangeIntervalId) {
      clearInterval(this.weightRangeIntervalId);
    }
    if (this.loggerIntervalId) {
      clearInterval(this.loggerIntervalId);
    }
  }

  cols: Array<TableColumn> = [
    new TableColumn('Menor preço', 'lowerShippingPrice', true, 'text'),
    new TableColumn('Preço médio', 'averageShippingPrice', true, 'text'),
    new TableColumn('Maior preço', 'greaterShippingPrice', true, 'text'),
    new TableColumn('Menor peso', 'lowerWeight', true, 'text'),
    new TableColumn('Peso médio', 'averageWeight', true, 'text'),
    new TableColumn('Maior peso', 'greaterWeight', true, 'text'),
    new TableColumn(
      'Total de linhas',
      'totalServiceCompanyRange',
      true,
      'number'
    ),
    new TableColumn('Responsável', 'responsible', true, 'text'),
    new TableColumn('Ambiente', 'environment', true, 'text'),
    new TableColumn('Data da atualização', 'dateCreated', true, 'date')
  ];

  async fetch(): Promise<void> {
    try {
      LoaderService.showLoader();
      this.weightRangeServiceCompanyRange.set(
        await lastValueFrom(
          this.integrationService
            .findAllWeightRangeServiceCompanyRange()
            .pipe(map((data) => data.result))
        )
      );
      await this.buttonDisabled();
      this.logger = await lastValueFrom(
        this.integrationService
          .findLoggerUpdateServiceCompanyRange()
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async fetchUpdateWeightRange(): Promise<void> {
    try {
      this.weightRangeServiceCompanyRange.set(
        await lastValueFrom(
          this.integrationService
            .findAllWeightRangeServiceCompanyRange()
            .pipe(map((data) => data.result))
        )
      );
      await this.buttonDisabled();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async fetchUpdateLogger(): Promise<void> {
    try {
      this.logger = await lastValueFrom(
        this.integrationService
          .findLoggerUpdateServiceCompanyRange()
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async cancelUpdate(): Promise<void> {
    try {
      LoaderService.showLoader();
      await lastValueFrom(
        this.integrationService.abortUpdateServiceCompanyRange()
      );
      await this.fetchUpdateWeightRange();
      await this.fetchUpdateLogger();
      await this.buttonDisabled();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async stopUpdate(): Promise<void> {
    try {
      LoaderService.showLoader();
      await lastValueFrom(
        this.integrationService.stopUpdateServiceCompanyRange()
      );
      await this.fetchUpdateWeightRange();
      await this.fetchUpdateLogger();
      await this.buttonDisabled();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async continueUpdate(): Promise<void> {
    try {
      LoaderService.showLoader();
      await lastValueFrom(
        this.integrationService.continueSyncServiceCompanyRange()
      );
      await this.fetchUpdateWeightRange();
      await this.fetchUpdateLogger();
      await this.buttonDisabled();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async syncUpdateServiceCompanyRange(): Promise<void> {
    try {
      LoaderService.showLoader();
      await lastValueFrom(this.integrationService.syncServiceCompanyRange());
      await this.fetchUpdateWeightRange();
      await this.buttonDisabled();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async updateServiceCompanyRange(): Promise<void> {
    try {
      LoaderService.showLoader();
      await lastValueFrom(this.integrationService.updateServiceCompanyRange());
      await this.buttonDisabled();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async rollbackServiceCompanyRange(): Promise<void> {
    try {
      LoaderService.showLoader();
      await lastValueFrom(this.integrationService.rollbackServiceCompany());
      await this.buttonDisabled();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async rollbackIsValid(): Promise<void> {
    try {
      this.rollbackButtonDisabled = !(await lastValueFrom(
        this.integrationService
          .rollbackIsValidServiceCompany()
          .pipe(map((data) => data.result))
      ));
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async buttonDisabled() {
    await this.rollbackIsValid();

    this.cancelButtonDisabled = this.weightRangeServiceCompanyRange()?.every(
      (obj) =>
        obj.status === 'ABORT' ||
        obj.status === 'FINISH' ||
        obj.status === 'NOT_START' ||
        obj.status === 'FAIL'
    );

    this.continueButtonDisabled = !this.weightRangeServiceCompanyRange()?.some(
      (obj) => obj.status === 'STOP'
    );

    this.stopButtonDisabled = !this.weightRangeServiceCompanyRange()?.some(
      (obj) => obj.status === 'PENDING'
    );

    this.updateButtonDisabled = !this.weightRangeServiceCompanyRange()?.every(
      (obj) => obj.status === 'FINISH' && obj.inProduction === 0
    );

    this.syncButtonDisabled = this.weightRangeServiceCompanyRange()?.some(
      (obj) => obj.status === 'PENDING'
    );
    if (this.syncButtonDisabled) {
      return;
    }
    this.syncButtonDisabled = !this.weightRangeServiceCompanyRange()?.every(
      (obj) =>
        obj.status === 'ABORT' ||
        obj.status === 'FINISH' ||
        obj.status === 'FAIL'
    );
  }

  getGridClass(status: string, inProduction: number): string {
    switch (status) {
      case 'PENDING':
        return 'pending';
      case 'FAIL':
        return 'fail';
      case 'STOP':
        return 'stop';
      case 'ABORT':
        return 'abort';
      case 'FINISH':
        return inProduction === 0 ? 'finish' : 'finish_in_production';
      case 'NOT_START':
        return 'not_start';
      default:
        return '';
    }
  }

  getDescription(status: string, inProduction: number): string {
    switch (status) {
      case 'PENDING':
        return 'Pendente';
      case 'FAIL':
        return 'Falha';
      case 'STOP':
        return 'Sincronismo pausado';
      case 'ABORT':
        return 'Cancelado';
      case 'FINISH':
        return inProduction === 0
          ? 'Finalizado e Pendente de envio para Produção'
          : 'Finalizado e em Produção';
      case 'NOT_START':
        return 'Não iniciado';
      default:
        return '';
    }
  }
}
