export enum SubscriberGroupEnum {
  'Pendentes' = 1,
  'CHURN' = 2,
  'Pendente_falha_de_pagamento' = 3,
  'Canceladas' = 4,
  'Expiradas' = 5,
  'Falhas_de_Pagamento' = 6,
  'Vouchers_Finalizados' = 7,
  'Recorrencia_Desabilitada' = 8,
  'Canceladas_Fraude' = 9,
  'Outros_saidas' = 999,
  'Mantidas' = 1000,
  'Pagamentos_Garantidos' = 1100,
  'Renovacao_com_troca_de_plano_cupom' = 1200,
  'Mantidas_com_voucher' = 1300,
  'Voucher_Expirando' = 1400,
  'Pendentes_Convertidas' = 1500,
  'Upgrade_Assinatura' = 1600,
  'Downgrade_Assinatura' = 1700,
  'Outros_mantidas' = 1999,
  'Conquistadas' = 2000,
  'Conquistadas_com_pagamento' = 2100,
  'Reativacoes_com_pagamento' = 2200,
  'Conquistadas_com_voucher' = 2300,
  'Edicao_Avulsa' = 2400,
  'Reativaçao_voucher' = 2500,
  'Edicao_Avulsa_Voucher' = 2600,
  'Outros_conquistas' = 2999
}

export function getGroupName(id: number): string {
  return SubscriberGroupEnum[id];
}
